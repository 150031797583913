/* eslint-disable no-param-reassign */
import initModules from "@/js/lib/initModules";

const fluidvids = require("fluidvids.js");

export default (global, availableModules) => {
  global = global || window.SHU;

  global.modules = global.modules || {};

  let debug = false;

  if (global.debug) {
    debug = !!global.debug;
  } else if (!process.env.NODE_ENV === "production") {
    debug = true;
  }

  global.modules = initModules(global.modules, availableModules, debug);

  fluidvids.init();

  document.documentElement.classList.remove("js-loading");
  document.documentElement.classList.add("js-loaded");
};
