import Vue from "vue";

import getBreakpoints from "@/js/helpers/breakpoint-helper";

import { toggleBooleanWithOveride } from "@/js/helpers/boolean-helper";

import Persistence from "./persistence";

const state = Vue.observable({
  // the courses known to the app and available to be
  // saved on this page
  knownCourses: {},
  minComparableItemCount: 2,
  maxComparableItemCount: 3,
  hasRecentlyChanged: false,
  compareFormConfig: {
    action: "/course-comparison",
  },
  isTrayExpanded: false,
  breakpoints: getBreakpoints(),
});

const persistedKeys = ["knownCourses"];

const store = {
  get state() {
    return state;
  },
  get canCompare() {
    return this.selectedCourseCount >= state.minComparableItemCount;
  },
  get courses() {
    return Object.values(state.knownCourses).sort((a, b) => a.timeSaved - b.timeSaved);
  },
  get savedCourses() {
    return this.courses.filter((s) => s.isSaved);
  },
  get selectedCourses() {
    return this.requiresManualSelection
      ? this.savedCourses.filter((s) => s.isSelected)
      : this.savedCourses;
  },

  /**
   * Breakpoint getters
   */
  get isMobile() {
    return !this.isTablet && !this.isDesktop;
  },
  get isTablet() {
    return state.breakpoints.medium && !this.isDesktop;
  },
  get isDesktop() {
    return state.breakpoints.large;
  },
  /**
   * UI state getters
   */
  get hasMinimumForCompare() {
    return this.savedCourseCount >= 2;
  },
  get selectedCourseCount() {
    return this.selectedCourses.length;
  },
  get savedCourseCount() {
    return this.savedCourses.length;
  },
  get isSelectedCoursesfull() {
    return this.selectedCourseCount >= state.maxComparableItemCount;
  },
  get hasTooFewSelectedCourses() {
    return this.selectedCourseCount < state.minComparableItemCount;
  },
  get requiresManualSelection() {
    return this.savedCourseCount > state.minComparableItemCount;
  },
};

const persistedStorage = new Persistence(store, {
  persistedKeys,
  prefix: "CourseCompare",
});

const getCourseByGuid = (CourseGuid) => state.knownCourses[CourseGuid];

const courseDefaultProps = {
  isSelected: false,
  isSaved: false,
  timeSaved: null,
};

export const actions = {
  loadFromPersistence(keys = persistedKeys) {
    Object.entries(persistedStorage.readAll(keys)).forEach(([stateKey, value]) => {
      if (value) {
        state[stateKey] = value;
      }
    });
  },
  toggleTray(forceValue = null) {
    state.isTrayExpanded = toggleBooleanWithOveride(state.isTrayExpanded, forceValue);
  },
  addKnownCourse(course) {
    const existingCourse = getCourseByGuid(course.CourseGuid) || {};

    Vue.set(state.knownCourses, course.CourseGuid, {
      ...courseDefaultProps,
      ...existingCourse,
      ...course,
    });
  },
  saveCourse({ CourseGuid }) {
    Vue.set(state.knownCourses, CourseGuid, {
      ...getCourseByGuid(CourseGuid),
      isSaved: true,
      timeSaved: new Date().getTime(),
    });
    this.hasChanged();
  },
  removeCourse({ CourseGuid }) {
    Vue.set(state.knownCourses, CourseGuid, {
      ...getCourseByGuid(CourseGuid),
      ...courseDefaultProps,
    });
    this.hasChanged();
  },
  toggleSavedCourse({ CourseGuid }) {
    const course = getCourseByGuid(CourseGuid);

    return course.isSaved ? this.removeCourse({ CourseGuid }) : this.saveCourse({ CourseGuid });
  },
  selectCourse({ CourseGuid }) {
    if (!store.isSelectedCoursesfull) {
      Vue.set(state.knownCourses, CourseGuid, {
        ...getCourseByGuid(CourseGuid),
        isSelected: true,
      });
    }
  },
  deselectCourse({ CourseGuid }) {
    Vue.set(getCourseByGuid(CourseGuid), "isSelected", false);
  },
  setCompareFormConfig(compareFormConfig) {
    state.compareFormConfig = compareFormConfig;
  },
  hasChanged() {
    state.hasRecentlyChanged = true;
    setTimeout(function reset() {
      state.hasRecentlyChanged = false;
    }, 1000);
  },
};

export const queries = {
  getCourseByGuid,
  isSelectable(course) {
    return course.isSelected || !store.isSelectedCoursesfull;
  },
};

persistedStorage.$on("change", ({ key }) => {
  actions.loadFromPersistence([key]);
});

export default store;
