<template>
  <article class="m-course-compare__course p-top--half p-bottom--half">
    <div class="m-course-compare__course-content">
      <input
        :id="id"
        :name="name"
        :disabled="!isSelected && !isSelectable"
        :checked="isSelected && hasCheckbox"
        :value="value"
        :type="inputType"
        :class="{ checkbox: hasCheckbox }"
        @change="toggleSelected"
      />

      <label :for="id">
        <div>
          <h3 class="h4">{{ course.CourseTitle }}</h3>

          <div class="m-course-compare__course-meta">
            <span class="meta">{{ course.CourseType }}</span>
            <span role="presentation">&#8194;|&#8194;</span>
            <span class="meta">{{ course.ModeOfAttendance }}</span>
            <span role="presentation">&#8194;|&#8194;</span>
            <span class="meta">{{ course.StudyLength }}</span>
          </div>
        </div>
      </label>
    </div>

    <button
      :title="`Remove '${course.CourseTitle}' from saved courses`"
      type="button"
      class="m-course-compare__course-remove"
      @click="removeCourse(course)"
    >
      <icon name="close" :height="8" :width="8" />
    </button>
  </article>
</template>
<script>
import Icon from "@/components/atoms/icon/Icon";

import { actions } from "../store";

export default {
  components: {
    Icon,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return `fav-${this.course.CourseGuid.toLowerCase()}`;
    },
    name() {
      return this.isSelected ? this.id : null;
    },
    inputType() {
      return this.hasCheckbox ? "checkbox" : "hidden";
    },
    value() {
      return this.isSelected ? `{${this.course.CourseGuid}}` : null;
    },
  },
  methods: {
    ...actions,
    toggleSelected({ target }) {
      return target.checked ? this.selectCourse(this.course) : this.deselectCourse(this.course);
    },
  },
};
</script>
