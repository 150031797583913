import Vue from "vue";

import { mapActions, mapState } from "vuex";

import ScApiHelper from "@/js/lib/solr/ScApiHelper";
import SearchUrlStateManager from "@/js/lib/solr/SearchUrlStateManager";

import {
  SET_SOLR_CONFIG,
  SET_RESULTS_SHOWN,
  SET_SOLR_AVAILABLE,
  SET_HAS_SOLR_CLIENT,
} from "@/js/search/store/mutations";

const ScApiPlugin = Vue.extend({
  data() {
    return {
      helper: null,
      urlManager: null,
      ready: false,
    };
  },
  computed: {
    ...mapState({
      useUrlParamState: ({ search }) => search.useUrlParamState,
    }),
  },
  methods: {
    ...mapActions(["setSearchState"]),
    initClient() {
      this.$store.commit(SET_HAS_SOLR_CLIENT, true);

      this.$store.commit(SET_SOLR_AVAILABLE, true);

      this.initUrlManager();

      this.helper = new ScApiHelper(this.$store);

      this.helper.$on("result", this.handleResults);

      this.helper.$on("ready", () => {
        this.ready = true;
        this.fetchAllSubjects();
        this.helper.search();
      });
    },
    initUrlManager() {
      this.urlManager = new SearchUrlStateManager({ store: this.$store });

      if (this.useUrlParamState) {
        this.urlManager.$on("statechange", this.setSearchState);
        this.urlManager.$on("statechange", this.urlManager.enable);
        this.urlManager.$on("loadedqs", () => this.$store.commit(SET_RESULTS_SHOWN, true));
      }

      this.urlManager.$on("linkqschange", (url) => this.$store.commit("SET_SEARCH_LINK_QS", url));
    },
    handleResults(result) {
      this.$store.dispatch("setResults", result).then(() => {
        this.$store.dispatch("setAllFacetValues", result.facets);
        this.fetchAllSubjects();
      });
    },
    fetchAllSubjects() {
      this.helper._search(this.helper.apiStateWithoutSubject, ({ facets }) => {
        const subjectFacet = facets.find(f => f.Name === "subject");

        this.$store.dispatch("setAvailableSubjectFacetValues", subjectFacet);
      });
    },
  },
  watch: {
    useUrlParamState(shouldUse) {
      if (shouldUse) {
        this.urlManager.enable();
      } else {
        this.urlManager.disable();
      }
    },
  },
});

export default function initScPlugin(store) {
  /* eslint-disable no-param-reassign */
  store.scApiPlugin = new ScApiPlugin({ store });

  store.subscribe(({ type }) => {
    if (type === SET_SOLR_CONFIG) {
      store.scApiPlugin.initClient();
    }
  });
}
