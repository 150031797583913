<template>
  <button type="button" class="m-course-compare__toggle" @click="$emit('toggle')">
    <span class="u-screen-reader-only"
      >{{ isExpanded ? `Hide` : `Show` }} courses saved for comparison</span
    >
    <icon :name="isExpanded ? `down` : `book`" :height="30" :width="30" />
  </button>
</template>
<script>
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
