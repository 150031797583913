<template>
  <!-- eslint-disable vue/no-v-html -->
  <div v-html="html"></div>
</template>
<script>
export default {
  props: {
    html: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
