import { orderBy, groupBy } from "lodash";

import { FACETS } from "@/js/search/constants/facets";

import { SET_FACET_VALUES, CLEAR_FACET_VALUES } from "@/js/search/store/mutations";

import { setFacetValues } from "@/js/search/store/helpers/facetHelper";

const IGNORE_GUID = "00000000000000000000000000000000";

function prepFacetValues(facet, dictionary) {
  return {
    name: facet.Name,
    values: facet.Values.map(function prepValue(v) {
      if (v.Name === IGNORE_GUID) {
        return false;
      }
      if (v.Name === "true" || dictionary[v.Name]) {
        return {
          name: facet.Name,
          label: dictionary[v.Name] || v.Name,
          count: v.Count,
          value: v.Name,
          disabled: !parseInt(v.Count, 10),
        };
      }
      return false;
    }).filter(Boolean),
  };
}

export default {
  state: {
    facets: FACETS,
  },
  mutations: {
    [SET_FACET_VALUES](state, { name, values }) {
      setFacetValues(state, "facets", name, values);
    },
    [CLEAR_FACET_VALUES](state, { name }) {
      const facet = state.facets.find((f) => f.name === name);

      facet.values = [];
    },
  },
  getters: {
    uiFacets({ facets }, getters, rootState, { facetRefinements }) {
      // determine if this facet is refined
      /* eslint-disable no-param-reassign */
      return facets.map((f) => {
        f.isRefined = false;

        f.values.forEach((v) => {
          if (facetRefinements.includes(v.value)) {
            v.checked = true;
            f.isRefined = true;
          } else {
            v.checked = false;
          }
        });
        /* eslint-enable no-param-reassign */
        return f;
      });
    },
    filtersForUi(state, { uiFacets }) {
      return {
        ...groupBy(orderBy(uiFacets, "displayOrder"), "displayGroup"),
        all: orderBy(uiFacets, "displayOrder"),
      };
    },
    appliedFacets(state, { uiFacets }) {
      return uiFacets
        .filter((f) => f.isRefined)
        .map((f) => {
          return {
            ...f,
            values: f.values.filter((v) => v.checked),
          };
        });
    },
  },
  actions: {
    setAllFacetValues({ state, getters, commit }, facets) {
      const newFacets = facets.reduce((acc, facet) => {
        acc[facet.Name] = prepFacetValues(facet, getters.dictionary);
        return acc;
      }, {});
      state.facets
        .filter((f) => f.values.length)
        .forEach((existingFacet) => {
          if (!newFacets[existingFacet.name]) {
            commit(CLEAR_FACET_VALUES, existingFacet);
          }
        });
      // eslint-disable-next-line no-unused-vars
      Object.entries(newFacets).forEach(([name, facet]) => commit(SET_FACET_VALUES, facet));
    },
    setAvailableSubjectFacetValues({ commit, getters }, subjectFacet) {
      commit(SET_FACET_VALUES, prepFacetValues(subjectFacet, getters.dictionary))
    },
  },
};
