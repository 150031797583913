<template>
  <component
    :is="navComponent"
    :wrapper-class="wrapperClass"
    :nav-items="navItems"
    :is-tier3-at-mobile="isTier3AtMobile"
    :tier3-nav="tier3Nav"
  />
</template>
<script>
import breakpointState from "@/js/helpers/breakpoint-helper";

import Tier2NavDesktop from "./desktop/Tier2NavDesktop";
import Tier2NavMobile from "./mobile/Tier2NavMobile";

const breakpoints = breakpointState();

export default {
  components: {
    Tier2NavDesktop,
    Tier2NavMobile,
  },
  props: {
    navItems: {
      type: Array,
      required: true,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    isTier3AtMobile: {
      type: Boolean,
      default: false,
    },
    tier3Nav: {
      type: [Array, Boolean],
      required: false,
      default: false,
    },
  },
  data: () => ({
    breakpoints,
  }),
  computed: {
    navComponent() {
      return this.breakpoints.medium ? Tier2NavDesktop : Tier2NavMobile;
    },
  },
};
</script>
