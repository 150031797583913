<template>
  <div>
    <nav class="m-nav m-nav--no-border">
      <a
        v-for="item in shownQuestions"
        :key="item.id"
        href="#"
        :class="{ 'nav-item--primary': isActive(item.id) }"
        class="nav-item nav-item--chevron"
        @click.prevent="show(item.id)"
      >
        <span>{{ item.question }}</span> <span class="chevron chevron--right"></span>
      </a>
    </nav>
    <div v-if="!isExpanded" class="p-top--double p-top--triple@large u-text-center">
      <a
        v-if="isExpandable"
        href="#"
        class="nav-item nav-item--chevron"
        @click.prevent="expandList"
      >
        <span>More questions</span>
        <span class="chevron" role="presentation"></span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    questions: {
      type: Array,
      required: true,
    },
    currentQuestion: {
      type: Object,
      default: null,
    },
    initialItems: {
      type: Number,
      default: 5,
    },
    defaultQuestion: {
      required: true,
      type: Object,
    },
    breakpoints: {
      required: true,
      type: Object,
    },
  },
  data: () => ({ isExpanded: false }),
  computed: {
    shownQuestions() {
      return this.isExpanded ? this.questions : this.questions.slice(0, this.initialItems);
    },
    isExpandable() {
      return this.questions.length > this.initialItems;
    },
    isDesktopMode() {
      return this.breakpoints.large;
    },
  },
  methods: {
    isCurrent(questionId) {
      const question = this.currentQuestion || this.defaultQuestion;

      return question.id === questionId;
    },
    show(questionId) {
      this.$emit("show", questionId);
    },
    expandList() {
      this.isExpanded = true;
    },
    isActive(questionId) {
      return this.isDesktopMode && this.isCurrent(questionId);
    },
  },
};
</script>
