<template>
  <a
    v-if="item && item.Url"
    :href="item.Url"
    class="link link--block link--plain link--underlined link--bold link--fancy island island--grey m-nav--tier3__title"
  >
    <span class="chevron chevron--left chevron--inline p-right--half"></span>
    {{ item.Title }}
  </a>
</template>
<script>
export default {
  props: {
    item: {
      type: [Object, null],
      required: false,
      default: null,
    },
    wrapperClass: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
