<template>
  <div>
    <back-link :item="grandparent" />
    <div class="wrapper">
      <nav class="m-nav-tier2-mobile__nav m-nav-nested">
        <nav-item v-if="parent" :item="parent" :class-name="parentClassName.join(' ')" />
        <div class="m-nav-nested__children">
          <nav-item
            v-for="child in contentNavItems"
            :key="child.Url"
            :item="child"
            class-name="m-nav-nested__child
                                link
                                link--block
                                link--plain
                                link--fancy
                                p-top--half p-bottom--half"
          />
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import NavItem from "../TierNavItem";
import BackLink from "../TierNavBackLink";

export default {
  components: {
    BackLink,
    NavItem,
  },
  props: {
    contentNavItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    activeItem() {
      return this.contentNavItems.find((i) => i.IsCurrent);
    },
    parent() {
      if (this.activeItem) {
        return this.activeItem.Parent;
      }
      if (this.contentNavItems.length) {
        return this.contentNavItems[0].Parent;
      }
      return null;
    },
    parentClassName() {
      const classes = [
        "m-nav-nested__parent",
        "link",
        "link--block",
        "link--fancy",
        "p-top",
        "p-bottom",
      ];

      return this.parent.IsCurrent ? classes : classes.concat(["link--plain", "link--black"]);
    },
    grandparent() {
      return this.parent && this.parent.Parent;
    },
  },
  methods: {
    hasChildren(navItem) {
      return navItem.Children && navItem.Children.length;
    },
  },
};
</script>
