import Vue from "vue";

import initStore from "@/js/search/initStore";
import OverlaySearch from "@/js/search/OverlaySearch";

import {
  SET_HITS_PER_PAGE,
  SET_RESULTS_SHOWN,
  SET_USE_URL_PARAM_STATE,
} from "@/js/search/store/mutations";

const initSearch = (CONFIG) => {
  const store = initStore(CONFIG);

  // store overides
  store.commit(SET_RESULTS_SHOWN, false);
  store.commit(SET_USE_URL_PARAM_STATE, false);
  store.commit(SET_HITS_PER_PAGE, 3);

  const searchContainer = document.querySelector(CONFIG.SELECTORS.CONTAINER);

  if (!searchContainer) {
    return null;
  }

  /* eslint-disable no-new */
  const app = new Vue({
    el: CONFIG.SELECTORS.CONTAINER,
    render: (h) =>
      h(OverlaySearch, {
        props: {
          portalSelector: "#search-overlay-portal",
        },
      }),
    store,
  });

  return {
    app,
    store,
    CONFIG,
  };
};

export default (configs) => configs.map((CONFIG) => initSearch(CONFIG));
