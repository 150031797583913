<template>
  <div v-if="totalPages">
    <div class="g g--no-gutters align-v-center">
      <div class="g__col">
        <label class="label" for="per-page">Results per page:</label>
      </div>

      <div class="g__col mg-left">
        <form-select
          id="per-page"
          name="per-page"
          class-name="select--grey"
          :selected="current"
          :options="optionsForSelect"
          @change="setPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { SET_HITS_PER_PAGE } from "@/js/search/store/mutations";

import FormSelect from "@/js/search/components/form-controls/FormSelect";

export default {
  components: {
    FormSelect,
  },
  computed: {
    ...mapState({
      perPage: ({ search }) => search.perPage,
      options: ({ ui }) => ui.config.PAGE_SIZE_OPTIONS || [10, 25, 50],
    }),
    ...mapGetters(["totalPages"]),
    current() {
      return this.optionsForSelect.find((o) => o.value === this.perPage);
    },
    optionsForSelect() {
      return this.options.map((opt) => {
        return {
          value: opt,
          label: opt,
        };
      });
    },
  },
  methods: {
    setPerPage(value) {
      this.$store.commit(SET_HITS_PER_PAGE, Number(value));
    },
  },
};
</script>
