<template>
  <div class="u-text-center p-top--quad p-bottom--quad">
    <img class="p-bottom" :src="noResultsEmoji" alt="No Results" />
    <h4>We found nothing using your filters.</h4>
    <p class="u-text--small">
      Try
      <reset-all-filters class="link" active-class-name="" title="clearing all subject filters" />
    </p>
  </div>
</template>

<script>
import noResultsEmoji from "@/assets/images/no-results-emoji.png";
import ResetAllFilters from "@/js/search/components/filters/ResetAllFilters";

export default {
  components: {
    ResetAllFilters,
  },
  data() {
    return {
      noResultsEmoji,
    };
  },
};
</script>
