import Vue from "vue";

import initStore from "@/js/search/initStore";
import InPageSearch from "@/js/search/InPageSearch";

const initSearch = (CONFIG) => {
  const store = initStore(CONFIG);

  const searchContainer = document.querySelector(CONFIG.SELECTORS.CONTAINER);

  if (!searchContainer) {
    return null;
  }

  /* eslint-disable no-new */
  const app = new Vue({
    el: CONFIG.SELECTORS.CONTAINER,
    render: (h) => h(InPageSearch),
    store,
  });

  return {
    app,
    store,
    CONFIG,
  };
};

export default (configs) => configs.map((CONFIG) => initSearch(CONFIG));
