<template>
  <a :href="item.Url" :class="[activeClass, className]">
    <span
      v-if="item.IsCurrent"
      class="chevron chevron--primary chevron--right chevron--inline p-right--half"
    >
    </span>
    {{ item.Title }}
  </a>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: `link
                    link--block
                    link--black
                    link--fancy
                    link--plain
                    p-top
                    p-bottom`,
    },
  },
  computed: {
    activeClass() {
      return this.item.IsCurrent ? "is--active" : "";
    },
  },
};
</script>
