import Vue from "vue";

const EVENT_OFFER_FOCUS = "offerfocus";

export const bus = new Vue();

export const offerFocus = ({ from, nativeEvent }) => {
  bus.$emit("offerfocus", { nativeEvent, from });
};

export const willAcceptFocus = ({ nativeEvent, from }, { trigger, target }) => {
  if (from === trigger) {
    nativeEvent.preventDefault();
    target.focus();
  }
};

export const focusSubscribe = (cb) => bus.$on(EVENT_OFFER_FOCUS, cb);
export const focusUnsubscribe = (cb) => bus.$off(EVENT_OFFER_FOCUS, cb);

export default {
  computed: {
    bus,
  },
  methods: {
    offerFocus,
    willAcceptFocus,
    focusSubscribe,
    focusUnsubscribe,
  },
};
