import { findIndex } from "lodash";

export function setFacetValues(state, key, name, values) {
  const facetIndex = findIndex(state[key], { name });

  if (facetIndex >= 0) {
    /* eslint-disable no-param-reassign */
    state[key][facetIndex].values = values;
  }
}

export function facetsForSearch(facets) {
  return facets.reduce((acc, facet) => {
    acc[facet.name] = [];
    return acc;
  }, {});
}

export function facetsForQs(facets, state) {
  return facets.reduce((acc, facet) => {
    acc[facet.name] = state.facets[facet.name];
    return acc;
  }, {});
}

export function mapFacetsState(facets) {
  return facets.reduce((acc, f) => {
    acc[f.name] = (state) => state.search.facets[f.name];
    return acc;
  }, {});
}

export function mapFacetsForSolr(facets) {
  return facets.reduce((acc, f) => {
    acc[f.name] = {
      paramType: f.paramType,
      field: f.field,
      mincount: 0,
    };
    return acc;
  }, {});
}

export default {
  facetsForQs,
  facetsForSearch,
  mapFacetsForSolr,
  mapFacetsState,
  setFacetValues,
};
