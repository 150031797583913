<template>
  <div class="m-page-component" :class="componentWrapperClass">
    <component
      :is="navComponent"
      :nav-items="navItems"
      :is-expandable="false"
      title="On this page"
      :current-item="currentItem"
      @visible="toggleFixedNav"
    />

    <component
      :is="navComponent"
      :nav-items="navItems"
      :is-expandable="true"
      :is-fixed="true"
      :is-shown="showFixedNav"
      :current-item="currentItem"
    />
  </div>
</template>
<script>
import { debounce, throttle } from "lodash";

import { getOffsetToBody } from "@/js/helpers/scroll-to-helper";

import breakpointState from "@/js/helpers/breakpoint-helper";
import scrollOffsetHelper from "../vue-mixins/scrollOffsetHelper";

import InPageNavDesktop from "./InPageNavDesktop";
import InPageNavMobile from "./InPageNavMobile";

const breakpoints = breakpointState();

export default {
  components: {
    InPageNavDesktop,
    InPageNavMobile,
  },
  mixins: [scrollOffsetHelper],
  props: {
    navItems: {
      type: Array,
      required: true,
    },
    wrapperClass: {
      type: String,
      default: "m-page-component--offset-top",
    },
  },
  data: () => ({
    breakpoints,
    currentItem: null,
    showFixedNav: false,
  }),
  computed: {
    navComponent() {
      return this.isDesktopMode ? InPageNavDesktop : InPageNavMobile;
    },
    isDesktopMode() {
      return this.breakpoints.medium;
    },
    componentWrapperClass() {
      const wrapperClasses = [];

      wrapperClasses.push(this.wrapperClass);

      return wrapperClasses;
    },
  },
  watch: {
    currentItem({ id }) {
      this.setHash(id);
    },
  },
  created() {
    window.addEventListener("scroll", this.setCurrentItemOnScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setCurrentItemOnScroll);
  },
  methods: {
    setCurrentItemOnScroll: throttle(function setCurrentItem() {
      const triggerPoint =
        (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) +
        this.scrollOffset;

      const sections = this.navItems.reduce(
        (acc, item) => {
          const { top } = getOffsetToBody(item.element);
          acc.tops.push(top);
          acc.items[top] = item;
          return acc;
        },
        {
          tops: [],
          items: {},
        }
      );

      const next = [...sections.tops].sort((a, b) => b - a).find((k) => k < triggerPoint);

      this.currentItem = sections.items[next] || this.navItems[0];
    }, 150),
    toggleFixedNav(staticVisible) {
      this.showFixedNav = !staticVisible;
    },
    setHash: debounce((id) => {
      window.history.replaceState(undefined, undefined, `#${id}`);
    }, 200),
  },
};
</script>
