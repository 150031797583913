<template>
  <div :class="wrapperClass">
    <button
      :aria-controls="expandableId"
      :aria-expanded="isExpanded"
      :title="btnTitle"
      class="link link--block link--white link--plain link--bold link--fancy island island--secondary island--full"
      @click.prevent="toggleExpanded"
    >
      <div class="wrapper">
        <div class="g align-v-center">
          <div class="g__col">In this section</div>
          <div class="g__col align-right m-nav-tier2-mobile__toggle-icon">
            <span v-if="isExpanded" class="close-kind--small" role="presentation"></span>
            <span v-else class="chevron" role="presentation"></span>
          </div>
        </div>
      </div>
    </button>
    <component
      :is="navType"
      v-show="isExpanded"
      :id="expandableId"
      :aria-hidden="!isExpanded"
      :content-nav-items="contentNavItems"
      :parent-item="parentItem"
    />
  </div>
</template>
<script>
import TierNavBase from "../TierNavBase";

import Tier2NavContentStacked from "./Tier2NavContentStacked";
import Tier2NavContentNested from "./Tier2NavContentNested";

export default {
  components: {
    Tier2NavContentStacked,
    Tier2NavContentNested,
  },
  extends: TierNavBase,
  props: {
    isTier3AtMobile: {
      type: Boolean,
      default: false,
    },
    tier3Nav: {
      type: [Array, Boolean],
      required: false,
      default: false,
    },
  },
  computed: {
    navType() {
      return this.isTier3AtMobile ? "Tier2NavContentNested" : "Tier2NavContentStacked";
    },
    btnTitle() {
      return `${this.isExpanded ? "Hide" : "Show"} section navigation`;
    },
    contentNavItems() {
      return this.isTier3AtMobile ? this.tier3Nav[0].navData : this.navItems;
    },
  },
};
</script>
