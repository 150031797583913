<template>
  <component :is="tag" :class="wrapperClass">
    <input
      :id="id"
      :checked="checked"
      :disabled="disabled"
      :type="type"
      :class="[type]"
      :name="name"
      @change.stop.prevent="onChange"
      @submit.stop.prevent
      @keydown.enter.stop.prevent
    />
    <label :for="id">
      <slot name="label">{{ label }}</slot>
    </label>
  </component>
</template>
<script>
import slugify from "underscore.string/slugify";
import { debounce } from "lodash";

export default {
  props: {
    type: {
      type: String,
      default: "checkbox",
      validate: (v) => ["radio", "checkbox"].includes(v),
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    tag: {
      type: String,
      required: false,
      default: "span",
    },
    value: {
      type: [String, Boolean],
      required: true,
    },
    wrapperClass: {
      type: [String, Object, Array],
      default: () => ["mg-bottom"],
    },
    checked: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    disabled: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      onChange: () => {},
    };
  },
  computed: {
    id() {
      return `checkbox-${slugify(this.name)}-${slugify(this.value)}`;
    },
  },
  created() {
    this.onChange = debounce(this.changeHandler, 5);
  },
  methods: {
    changeHandler(event) {
      if (!this.disabled) {
        this.$emit("change", {
          name: this.name,
          label: this.label,
          value: this.value,
          checked: event.target.checked,
        });
      }
    },
  },
};
</script>
