<template>
  <div>
    <div class="mg-bottom--half">
      <h4 v-if="facet.displayTitle" class="m-accordion__title-text" :id="`${facet.name}FieldsetHeader`">{{ facet.displayTitle }}</h4>
    </div>
    <form-multi-select
      :id="facet.name"
      :label="facet.displayTitle"
      :options="facet.values"
      :value="currentValues"
      @change="onChange"
      />
  </div>
</template>

<script>
import { TOGGLE_SEARCH_FACET_VALUE } from "@/js/search/store/mutations";

import FormMultiSelect from "@/js/search/components/form-controls/FormMultiSelect";

export default {
  props: {
    facet: {
      required: true,
      type: Object,
    },
  },
  components: {
    FormMultiSelect,
  },
  computed: {
    currentValues() {
      return this.facet.values.filter((value) => value.checked);
    },
  },
  methods: {
    onChange(value) {
      this.$store.commit(TOGGLE_SEARCH_FACET_VALUE, { name: this.facet.name, value });
    },
  },
};
</script>
