export const FACETS = [
  {
    displayTitle: "Subject Area",
    name: "subject",
    summaryFilters: true,
    field: "subject_s",
    type: "MultiboxFacet",
    displayOrder: 1,
    displayGroup: "main",
    paramType: Array,
    condition: (config) => !(config?.PRELOAD_QUERY?.SUBJECT_AREA?.length),
    values: [],
  },
  {
    displayTitle: "Attendance",
    name: "modeofattendance",
    summaryFilters: true,
    field: "modeofattendance_s",
    type: "CheckboxFacet",
    displayOrder: 2,
    displayGroup: "main",
    paramType: String, // the type to use when building the facet value to send in a query
    values: [],
  },
  {
    displayTitle: "Course type",
    name: "coursetype",
    summaryFilters: true,
    field: "coursetype_s",
    type: "CheckboxFacet",
    displayOrder: 3,
    displayGroup: "main",
    paramType: String,
    values: [],
  },
  {
    displayTitle: "Location",
    name: "studylocation",
    field: "studylocation_s",
    type: "CheckboxFacet",
    displayOrder: 4,
    displayGroup: "main",
    paramType: String,
    values: [],
  },
  {
    displayTitle: false,
    name: "inclearing",
    field: "inclearing_b",
    type: "SingleCheckboxFacet",
    checkboxLabel: "Clearing courses",
    displayOrder: 5,
    displayGroup: "additional",
    condition: "CLEARING.SHOW_UI",
    paramType: String,
    values: [],
  },
  {
    displayTitle: false,
    name: "hasfoundationyear",
    field: "hasfoundationyear_b",
    type: "SingleCheckboxFacet",
    checkboxLabel: "Foundation Year courses",
    displayOrder: 6,
    displayGroup: "additional",
    condition: true,
    paramType: String,
    values: [],
  },
  {
    displayTitle: false,
    name: "opentointernationalstudents",
    field: "opentointernationalstudents_s",
    type: "SingleCheckboxFacet",
    checkboxLabel: "Open to non-EU students",
    displayOrder: 7,
    displayGroup: "additional",
    solrType: "terms",
    values: [],
  },
];
