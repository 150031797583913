<template>
  <featured-search-wrapper :input-id="id" label="Search term" heading="Search courses">
    <query-input
      :id="id"
      :name="id"
      class-name="m-site-search__input"
      placeholder="Search for something…"
    />
    <button type="button" class="m-site-search__btn" @click.prevent="showOverlay">
      <span class="sr-only">Search</span>
      <icon name="search" title="Search icon" class="u-text-color-primary" />
    </button>
  </featured-search-wrapper>
</template>
<script>
import uuid from "@/js/lib/uuid";

import { SET_RESULTS_SHOWN } from "@/js/search/store/mutations";

import SearchBox from "@/js/search/components/query/SearchBox";
import Icon from "@/components/atoms/icon/Icon";
import FeaturedSearchWrapper from "@/js/search/components/FeaturedSearchWrapper";

export default {
  components: {
    FeaturedSearchWrapper,
    Icon,
  },
  extends: SearchBox,
  data() {
    return {
      id: `search-${uuid()}`,
    };
  },
  methods: {
    showOverlay() {
      this.$store.commit(SET_RESULTS_SHOWN, true);
    },
  },
};
</script>
