<template>
  <component :is="tag">
    <form-option-control
      v-for="option in sortedOptions"
      :key="option.value"
      :checked="option.checked"
      :name="option.name"
      :disabled="option.disabled"
      :tag="tag"
      :type="type"
      :value="option.value"
      :wrapper-class="controlWrapperClass"
      @change="onChange"
    >
      <template v-slot:label>
        {{ option.label }}&nbsp;<span v-if="showCount && option.count"
          >({{ option.count
          }}<span class="u-screen-reader-only"> matches found for this option</span>)</span
        >
      </template>
    </form-option-control>
  </component>
</template>
<script>
import FormOptionControl from "@/js/search/components/form-controls/FormOptionControl";
import sortBy from "lodash.sortby";

export default {
  components: {
    FormOptionControl,
  },
  props: {
    options: {
      required: true,
      type: Array,
    },
    type: {
      type: String,
      default: "checkbox",
      validate: (v) => ["radio", "checkbox"].includes(v),
    },
    tag: {
      type: String,
      default: "div",
    },
    controlWrapperClass: {
      type: [String, Object, Array],
      default: undefined,
    },
    showCount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sortedOptions() {
      return sortBy(this.options, "label");
    },
  },
  methods: {
    onChange(option) {
      this.$emit("change", option);
    },
  },
};
</script>
