<template>
  <button
    :aria-label="label"
    :disabled="!hasNextPage"
    class="m-pagination__control"
    @click.prevent="pageNext"
  >
    <span class="chevron chevron--right chevron--black"></span>
  </button>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["hasNextPage", "pageForUi"]),
    label() {
      return this.hasNextPage ? `Go to page ${this.pageForUi + 1}` : "Go to next page (disabled)";
    },
  },
  methods: {
    ...mapActions(["pageNext"]),
  },
};
</script>
