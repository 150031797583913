<script>
import QueryInput from "@/js/search/components/query/QueryInput";

export default {
  components: {
    QueryInput,
  },
};
</script>

<template>
  <div class="control">
    <label for="keywords" class="label label--spaced">Search terms:</label>
    <query-input id="keywords" class-name="input" />
  </div>
</template>
