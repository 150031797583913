import Vue from "vue";
import tier2Nav from "./vue-components/Tier2Nav";

export default (config, { tier3Nav = false }) =>
  config.map(({ navTarget, navData, wrapperClass }) => {
    const app = new Vue({
      el: navTarget,
      name: "Tier2NavContainer",
      data: {
        navData,
      },
      render(h) {
        return h(tier2Nav, {
          props: {
            navItems: this.navData,
            wrapperClass,
            tier3Nav,
            isTier3AtMobile: tier3Nav && tier3Nav instanceof Array && !!tier3Nav.length,
          },
        });
      },
    });

    return {
      config,
      app,
    };
  });
