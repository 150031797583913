<template>
  <form-option-control
    :name="facet.name"
    :disabled="!config.count"
    :value="config.value"
    :checked="checked"
    type="checkbox"
    @change="onChange"
  >
    <template v-slot:label>
      {{ checkboxLabel }}&nbsp;<span v-if="config.count" class="u-text-color-primary"
        >({{ config.count }})</span
      >
    </template>
  </form-option-control>
</template>
<script>
import FormOptionControl from "@/js/search/components/form-controls/FormOptionControl";

import { mapState } from "vuex";

import { SET_SEARCH_FACET_VALUE, UNSET_SEARCH_FACET_VALUE } from "@/js/search/store/mutations";

export default {
  components: {
    FormOptionControl,
  },
  props: {
    facet: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      searchFacets: ({ search }) => search.facets,
      uiLabels: ({ search }) => search.uiLabels,
      solrDictionary: ({ search }) => search.solrDictionary,
    }),
    config() {
      return this.facet.values.length ? this.facet.values[0] : this.defaultConfig;
    },
    checkboxLabel() {
      const guid = this.uiLabels.FACETS[this.facet.name];

      if (guid) {
        return this.solrDictionary[guid] || this.facet.checkboxLabel;
      }
      return this.facet.checkboxLabel;
    },
    defaultConfig() {
      return {
        value: true,
        count: null,
      };
    },
    searchFacet() {
      return this.searchFacets[this.facet.name];
    },
    checked() {
      return this.searchFacet.length;
    },
  },
  methods: {
    onChange({ name, checked, value }) {
      const mutation = checked ? SET_SEARCH_FACET_VALUE : UNSET_SEARCH_FACET_VALUE;

      this.$store.commit(mutation, { name, value });
    },
  },
};
</script>
