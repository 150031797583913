<template>
  <div class="m-modal m-modal--inline">
    <div class="m-modal__inner">
      <h3>{{ result.question }}</h3>
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="result.answer"></div>
    </div>
  </div>
</template>
<script>
import { scrollToTarget } from "@/js/helpers/scroll-to-helper";

export default {
  props: {
    currentQuestion: {
      required: false,
      type: [Object, null],
      default: null,
    },
    defaultQuestion: {
      required: true,
      type: Object,
    },
  },
  computed: {
    result() {
      return this.currentQuestion || this.defaultQuestion;
    },
  },
  watch: {
    currentQuestion(question) {
      if (question) {
        scrollToTarget(this.$el, { offset: 40 });
      }
    },
  },
};
</script>
