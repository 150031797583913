<script>
export default {
  props: {
    navItems: {
      type: Array,
      required: true,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    isExpanded: false,
  }),
  computed: {
    expandableId: () => `nav-${Math.floor(Math.random() * 100000)}`,
    currentNavItem() {
      return this.navItems.find((i) => i.IsCurrent);
    },
    parentItem() {
      if (this.currentNavItem) {
        return this.currentNavItem.Parent;
      }
      return null;
    },
    isTopLevel() {
      return !this.parentItem;
    },
    topLevelTitle() {
      if (this.isTopLevel && this.navItems.length && this.navItems[0].Parent) {
        return this.navItems[0].Parent.Title;
      }
      return null;
    },
  },
  methods: {
    activeClass(navItem) {
      return navItem.IsCurrent ? "is--active" : "";
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
