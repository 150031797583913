<script>
import throttle from "lodash.throttle";

import { scrollToTarget, getOffsetToBody } from "@/js/helpers/scroll-to-helper";

import scrollOffsetHelper from "../vue-mixins/scrollOffsetHelper";

export default {
  mixins: [scrollOffsetHelper],
  props: {
    navItems: {
      type: Array,
      required: true,
    },
    currentItem: {
      type: Object,
      default: null,
    },
    isExpandable: {
      type: Boolean,
      default: true,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // is scrolling right now
      isAutoScrolling: false,
      // additional padding to apply when calculating whether to emit visibility
      visibilityBufferPadding: 50,
    };
  },
  created() {
    if (!this.isFixed) {
      window.addEventListener("scroll", this.emitVisibility);
    }
    this.$nextTick(() => {
      const hashItem = this.getNavItemById(document.location.hash.replace("#", ""));

      if (hashItem) {
        this.scrollToSection(hashItem);
      }
    });
  },
  beforeDestroy() {
    if (!this.isFixed) {
      window.removeEventListener("scroll", this.emitVisibility);
    }
  },
  methods: {
    isActive(navItem) {
      return navItem === this.currentItem;
    },
    scrollToSection(navItem) {
      if (!this.isAutoScrolling) {
        this.isAutoScrolling = true;

        scrollToTarget(navItem.element, { offset: this.scrollOffset }).then(() => {
          this.isAutoScrolling = false;
        });
      }
    },
    emitVisibility: throttle(function emit() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const elementBottom =
        getOffsetToBody(this.$el).top + this.$el.offsetHeight - this.visibilityBufferPadding;

      if (scrollTop > elementBottom) {
        this.$emit("visible", false);
      } else {
        this.$emit("visible", true);
      }
    }, 100),
    getNavItemById(id) {
      return this.navItems.find((navItem) => navItem.id === id);
    },
  },
};
</script>
