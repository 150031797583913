import Vue from "vue";
import merge from "lodash.merge";

import getBreakpoints from "@/js/helpers/breakpoint-helper";

import { toggleBooleanWithOveride } from "@/js/helpers/boolean-helper";

const state = Vue.observable({
  navData: [],
  // @todo pull this from config
  siteSearchData: {
    formAttrs: {
      method: "POST",
      action: "/api/sitecore/NavigationSearch/NavigationSearchSubmit",
    },
    queryInputAttrs: {
      name: "Request.Query",
      placeholder: "Search our website & courses",
    },
    label: `Search our website &amp; courses`,
  },
  isMenuExpanded: false,
  isSearchExpanded: false,
  breakpoints: getBreakpoints(),
});

export const actions = {
  setNavData(data) {
    state.navData = data;
  },
  mergeSiteSearchData(data) {
    state.siteSearchData = merge(state.siteSearchData, data);
  },
  toggleMenu(forceValue = null) {
    state.isMenuExpanded = toggleBooleanWithOveride(state.isMenuExpanded, forceValue);
    state.isSearchExpanded = false;
  },
  toggleSearch(forceValue = null) {
    state.isSearchExpanded = toggleBooleanWithOveride(state.isSearchExpanded, forceValue);
    state.isMenuExpanded = false;
  },
};

export default {
  get state() {
    return state;
  },
  get isMobile() {
    return !this.isTablet && !this.isDesktop;
  },
  get isTablet() {
    return state.breakpoints.medium && !this.isDesktop;
  },
  get isDesktop() {
    return state.breakpoints.large;
  },
};
