import throttle from "lodash.throttle";

export default {
  data: () => ({
    additionalScrollSpace: 30,
    scrolledHeight: null,
    scrollHeightResizeHandler: null,
  }),
  mounted() {
    this.$nextTick(() => {
      this.setScrolledHeight();
    });
    this.scrollHeightResizeHandler = throttle(this.setScrolledHeight, 150);

    window.addEventListener("resize", this.scrollHeightResizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scrollHeightResizeHandler);
  },
  computed: {
    scrollOffset() {
      return (this.scrolledHeight || this.$el.offsetHeight) + this.additionalScrollSpace;
    },
  },
  methods: {
    setScrolledHeight() {
      this.scrolledHeight = this.$el.offsetHeight;
    },
  },
};
