<script>
import { mapState, mapGetters } from "vuex";
import { SET_RESULTS_SHOWN } from "@/js/search/store/mutations";

export default {
  computed: {
    ...mapState({
      solrAvailable: ({ search }) => search.solrAvailable,
      hasSolrClient: ({ search }) => search.hasSolrClient,
    }),
    ...mapGetters(["hasSearched", "hits", "isMobile", "isTablet", "showResults"]),
  },
  watch: {
    hasSearched() {
      this.$store.commit(SET_RESULTS_SHOWN, true);
    },
  },
};
</script>
