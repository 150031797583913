<template>
  <div>
    <input
      :id="`${id}-${value}`"
      class="checkbox"
      :name="id"
      :value="value"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />
    <label :for="`${id}-${value}`">
      <span class="peer-disabled:cursor-not-allowed" v-html="displayLabel(label)"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
  },
  methods: {
    displayLabel(label) {
      return this.search !== ""
        ? label.replace(new RegExp(this.search, "gi"), '<span class="font-bold">$&</span>')
        : label;
    },
  },
};
</script>
