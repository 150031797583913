<script>
import Mousetrap from "mousetrap";
import getUuid from "@/js/lib/uuid";

import { focusSubscribe, focusUnsubscribe, offerFocus } from "@/js/helpers/focus-helper";

import store, { actions } from "@/components/molecules/masthead/store";

const uuid = getUuid();

export default {
  data() {
    return store;
  },
  created() {
    this.$nextTick(() => focusSubscribe(this.acceptFocus));
  },
  beforeDestroy() {
    focusUnsubscribe(this.acceptFocus);
  },
  methods: {
    ...actions,
    id(prefix = "item") {
      return `masthead-${prefix}-${uuid}`;
    },
    bindTabKey({ from }) {
      Mousetrap.bind("tab", (nativeEvent) => {
        offerFocus({ from, nativeEvent });
      });
    },
    unbindTabKey() {
      Mousetrap.unbind("tab");
    },
    acceptFocus: () => {},
  },
};
</script>
