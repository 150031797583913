<template>
  <div class="m-snippet">
    <div class="g">
      <div class="g__col g__col-12">
        <div class="g align-v-center">
          <div class="g__col">
            <div class="u-trim-pad">
              <span class="meta meta--tight">{{ hit.CourseAwardName }}</span>
            </div>
          </div>
        </div>
        <a :href="hit.CourseUrl" class="m-snippet__link">
          <h3 class="h2 m-snippet__heading">{{ hit.CourseTitle }}</h3>
        </a>
        <div class="mg-bottom--half">
          <span class="pill">{{ hit.CourseType }}</span
          >&nbsp; <span class="pill pill--secondary">{{ hit.ModeOfAttendance }}</span
          >&nbsp; <span class="pill pill--secondary">{{ hit.StudyLength }}</span
          >&nbsp;
          <span v-if="hit.UcasPoints" class="pill pill--secondary">{{ hit.UcasPoints }}</span>
        </div>

        <p class="mg-bottom">{{ hit.CourseSummary | stripHtml | widont }}</p>
        <div v-if="showSubject" class="mg-bottom">
          <subject-area-pill :subject="hit.Subject" />
        </div>
      </div>
    </div>

    <div class="g">
      <div class="g__col g__col-12 g__col-6@large">
        <span class="meta meta--tight meta--grey">
          {{ hit.YearOfEntry }}
          <template v-if="hit.UcasCode"> | UCAS: {{ hit.UcasCode }} </template>
        </span>
      </div>

      <div class="g__col g__col-12 g__col-6@large u-text-right@large">
        <template v-if="hit.NoLongerRecruiting">
          <span class="meta meta--tight">
            <span class="status-indicator status-indicator--red"></span>
            {{ uiLabels.NO_LONGER_RECRUITING }}
          </span>
        </template>
        <template v-else-if="enableClearing && hit.IsInClearing">
          <span class="meta meta--tight">
            <span class="status-indicator status-indicator--green"></span>
            {{ uiLabels.COURSE_IN_CLEARING }}
          </span>
        </template>
      </div>
    </div>
    <hr class="hr--small" />
  </div>
</template>
<script>
import { mapState } from "vuex";

import trackingHelper from "@/js/search/mixins/trackingHelper";

import SubjectAreaPill from "@/js/search/components/results/SubjectAreaPill";

// const courseKeyRe = /_(t|b)$/g;

// function mapStringKeys(keys) {
//   return keys.reduce((acc, key) => {
//     const name = key.replace(courseKeyRe, "");

//     acc[name] = ($vm) => $vm.hit[key];

//     return acc;
//   }, {});
// }

// function mapSolrDictionaryKeys(keys) {
//   const re = /_s$/g;

//   return keys.reduce((acc, key) => {
//     const name = key.replace(re, "");

//     acc[name] = ($vm) => $vm.dictionary[$vm.hit[key]];

//     return acc;
//   }, {});
// }

export default {
  components: {
    SubjectAreaPill,
  },
  filters: {
    widont: (str) => (str ? str.replace(/\s([^\s]+)\s*$/, "\u00A0$1") : str),
    stripHtml: (str) => (str ? str.replace(/(<([^>]+)>)/gi, "") : str),
  },
  mixins: [trackingHelper],
  props: {
    hit: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState({
      uiLabels: ({ search }) => search.uiLabels,
      dictionary: ({ search }) => search.solrDictionary,
      showSubject: ({ search }) => !search.enforcedSubjectArea,
      enableClearing: ({ ui }) =>
        ui.config.CLEARING ? ui.config.CLEARING.SHOW_UI || ui.config.CLEARING.ENFORCED : false,
    }),
    // ...mapStringKeys([
    //   "coursetitle_t",
    //   "coursesummary_t",
    //   "studylength_t",
    //   "ucascode_t",
    //   "inclearing_b",
    //   "nolongerrecruiting_b",
    //   "hasfoundationyear_b",
    // ]),
    // ...mapSolrDictionaryKeys([
    //   "subject_s",
    //   "yearofentry_s",
    //   "coursetype_s",
    //   "studylocation_s",
    //   "modeofattendance_s",
    //   "opentointernationalstudents_s",
    //   "department_s",
    //   "awardname_s",
    // ]),
    // url() {
    //   return this.hit._fullpath
    //     .replace("/sitecore/content/main site/home", "")
    //     .replace(/\s/g, "-");
    // },
  },
  // methods: {
  //   lookup(key) {
  //     return this.dictionary[key];
  //   },
  // },
};
</script>
