import Vue from "vue";
import qs from "qs";

import { mapGetters, mapState } from "vuex";

export default function SolrUrlStateManager({ store, config }) {
  const defaults = {
    isEnabled: true,
    stashQsParams: ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"],
    stashState: {},
    ...config,
  };

  return new Vue({
    store,
    data() {
      return {
        ...defaults,
        queryString: window.location.search.replace("?", ""),
        originalQs: window.location.search,
      };
    },
    computed: {
      ...mapState({
        useUrlParamState: ({ search }) => search.useUrlParamState,
      }),
      ...mapGetters([
        "searchStateForQs",
        "searchStateForLinkQs",
        "integerStateKeys",
        "showResults",
      ]),
      location: () => window.location,
      docTitle: () => document.title,
      history: () => window.history,
      baseUrl: () => `${window.location.origin}${window.location.pathname}`,
      searchUrl() {
        return `${this.baseUrl}?${this.queryString}${this.location.hash}`;
      },
      urlWithOriginalQs() {
        return `${this.baseUrl}${this.originalQs ? this.originalQs : ""}${this.location.hash}`;
      },
    },
    watch: {
      searchStateForQs(state) {
        this.setQs({ ...state, ...this.stashState });
      },
      searchUrl() {
        if (this.isEnabled) {
          this.updateUrl();
        }
      },
      searchStateForLinkQs(state) {
        this.$emit("linkqschange", this.encode(state));
      },
      showResults(resultsAreShown) {
        if (this.isEnabled && resultsAreShown) {
          this.updateUrl();
        }
      },
      isEnabled: {
        immediate: true,
        handler(isEnabled) {
          return isEnabled ? this.updateUrl() : this.restoreQs();
        },
      },
      useUrlParamState(useState) {
        return useState ? this.enable() : this.disable();
      },
    },
    created() {
      this.$nextTick(() => {
        if (this.useUrlParamState && (this.readQs() || this.showResults)) {
          this.enable();
          this.$emit("loadedqs");
        } else {
          this.disable();
        }
      });
    },
    methods: {
      updateUrl() {
        this.history.replaceState({}, this.docTitle, this.searchUrl);
      },
      setQs(state) {
        this.queryString = this.encode(state);
      },
      readQs() {
        const state = this.decode(this.location.search);

        if (Object.keys(state).length) {
          const [searchState, stashState] = this.splitObject(state, this.stashQsParams);

          this.setStashState(stashState);

          if (Object.keys(searchState).length) {
            this.$emit("statechange", state);
          }
        }
      },
      splitObject: (obj, splitList) =>
        Object.entries(obj).reduce(
          ([subtractedObj, newObj], [key, value]) => {
            const target = splitList.includes(key) ? newObj : subtractedObj;

            target[key] = value;

            return [subtractedObj, newObj];
          },
          [{}, {}]
        ),
      setStashState(stashState) {
        this.stashState = stashState;
      },
      clearStashState() {
        this.stashState = {};
      },
      restoreQs() {
        this.history.replaceState({}, this.docTitle, this.urlWithOriginalQs);
      },
      encode: (state) => qs.stringify(state, { encodeValuesOnly: true }),
      decode(str) {
        return Object.entries(qs.parse(str, { ignoreQueryPrefix: true })).reduce((acc, [k, v]) => {
          acc[k] = this.parseParam(k, v);
          return acc;
        }, {});
      },
      parseParam(k, v) {
        let parsed = v;
        if (Array.isArray(v)) {
          parsed = v.map((subValue) => this.parseParam(k, subValue));
        } else if (this.integerStateKeys.includes(k)) {
          parsed = parseInt(v, 10);
        }
        // TD 24-02-08: disabled as appears to break hasfoundationyear filter when restoring query from URL
        // else if (v === "true") {
        //   parsed = true;
        // } else if (v === "false") {
        //   parsed = false;
        // }

        return parsed;
      },
      enable() {
        this.isEnabled = true;
      },
      disable() {
        this.isEnabled = false;
      },
    },
  });
}
