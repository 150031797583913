import Vue from "vue";

import { mapGetters, mapState } from "vuex";

import { partial, debounce } from "lodash";

import { FACETS } from "@/js/search/constants/facets";

import { mapFacetsForSolr, mapFacetsState } from "@/js/search/store/helpers/facetHelper";

import SearchError from "@/js/search/errors/SearchError";

// executed bound to $vm instance
function FacetWatcherFactory(f, { addMethod }) {
  return function watchFacet(newValues) {
    this[addMethod](f.name, newValues);
  };
}

function mapWatchers(factory, facets, opts) {
  return facets.reduce((acc, f) => {
    acc[f.name] = factory(f, opts);

    return acc;
  }, {});
}

const mapFacetWatchers = partial(mapWatchers, FacetWatcherFactory);

export default function ScApiHelper(store) {
  return new Vue({
    store,
    data: {
      facets: {
        ...mapFacetsForSolr(FACETS),
      },
      searchDebounceAmount: 120,
      disjunctiveFilters: {},
      lastResult: "",
      search: () => {},
    },
    computed: {
      ...mapGetters(["query", "page"]),
      ...mapState({
        perPage: ({ search }) => search.perPage,
        yearOfEntry: ({ search }) => search.yearOfEntry,
        subjectAreas: ({ search }) => search.subjectAreas,
        enforcedSubjectArea: ({ search }) => search.enforcedSubjectArea,
        reverseDict: ({ search }) => search.reverseDictionary,
        config: ({ ui }) => ui.config,
        ...mapFacetsState(FACETS),
        clearingConfig() {
          return this.config.CLEARING || {};
        },
      }),
      apiState() {
        const initialState = {
          page: this.page,
          pagesize: this.perPage,
          query: this.query,
          yearofentry: this.reverseDict[this.yearOfEntry],
          subject: this.subjectFilterForApi,
          coursetype: "",
          modeofattendance: "",
          opentointernationalstudents: "",
          hasfoundationyear: "",
          inclearing: "",
        };

        return {
          ...initialState,
          ...this.disjunctiveFilterForApi,
        };
      },
      apiStateWithoutSubject() {
        return {
          ...this.apiState,
          subject: [],
        };
      },
      disjunctiveFilterForApi() {
        const filterObj = {};

        Object.entries(this.disjunctiveFilters)
          .map(([field, values]) => {
            const paramType = this.facets[field].paramType ?? String;

            switch (paramType) {
              case String:
                filterObj[field] = values.join(",");
                break;
              case Array:
                filterObj[field] = values;
                break;
              default:
                throw new SearchError(`Unknown paramType ${paramType}`);
            }

            return filterObj;
          })
          .filter(Boolean);
        return filterObj;
      },
      subjectFilterForApi() {
        return this.enforcedSubjectArea
          ? this.enforcedSubjectFilterForApi
          : this.subjectAreas.map((value) => this.reverseDict[value]).flat();
      },
      enforcedSubjectFilterForApi() {
        return this.enforcedSubjectArea ? this.enforcedSubjectArea : "";
      },
    },
    watch: {
      apiState() {
        this.search();
      },
      ...mapFacetWatchers(FACETS, {
        addMethod: "setFacetFilter",
      }),
    },
    created() {
      this.search = debounce(() => {
        this._search();
      }, this.searchDebounceAmount);

      this.$nextTick(() => {
        this.$emit("ready");
      });
    },
    methods: {
      _search(searchState = null, cb = null) {
        const url = this.config.API_URL;
        const params = searchState ?? this.apiState;
        const req = new XMLHttpRequest();

        req.onreadystatechange = function query(vm) {
          if (req.readyState === 4) {
            const res = JSON.parse(req.response);
            const lastResult = {
              hits: res.Results,
              nbHits: res.TotalCount,
              facets: res.Facets,
            };
            if (cb) {
              cb(lastResult);
            } else {
              // eslint-disable-next-line no-param-reassign
              vm.lastResult = lastResult;
              vm.$emit("result", vm.lastResult);
            }
          }
        }.bind(req, this);

        req.open("POST", url, true);
        req.setRequestHeader("Content-Type", "application/json");
        req.send(JSON.stringify(params));
      },
      setFacetFilter(name, values) {
        this.$set(this.disjunctiveFilters, name, values);
      },
    },
  });
}
