import Vue from "vue";

import InPageNav from "./vue-components/InPageNav";

export default (config) =>
  config.map((c) => {
    const navItems = [...document.querySelectorAll(`[${c.sectionAttr}]`)].map((el) => {
      return {
        title: el.getAttribute(c.sectionAttr).trim(),
        element: el,
        id: el.id,
      };
    });

    const app = new Vue({
      el: c.navTarget,
      name: "InPageNavContainer",
      data: {
        navItems,
      },
      render(h) {
        return h(InPageNav, {
          props: {
            navItems: this.navItems,
            wrapperClass: c.wrapperClass,
          },
        });
      },
    });

    return {
      config,
      navItems,
      app,
    };
  });
