<template>
  <form class="m-page-component--inpage" @submit.prevent @keydown.enter.stop.prevent>
    <div v-if="hasSidebar" class="g align-v-center">
      <div class="g__col">
        <h3>Refine results</h3>
      </div>
      <div class="g__col align-right u-text-right">
        <reset-all-filters class="link" active-class="link--primary" />
      </div>
    </div>
    <div class="island island--grey island--bordered-full p--double">
      <filters-mobile />
    </div>
  </form>
</template>
<script>
import { mapGetters } from "vuex";

import ResetAllFilters from "@/js/search/components/filters/ResetAllFilters";
import FiltersMobile from "@/js/search/components/filters/FiltersMobile";

export default {
  components: {
    FiltersMobile,
    ResetAllFilters,
  },
  computed: {
    ...mapGetters(["hasSidebar"]),
  },
};
</script>
