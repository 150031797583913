<template>
  <div>
    <transition-group name="fade" tag="div" :class="outerClass" mode="out-in">
      <slot name="hits">
        <hit v-for="hit in hits" :key="hit.ItemFullPath" :hit="hit" />
      </slot>
    </transition-group>
    <transition name="fadeUp">
      <no-results v-if="!hits.length"></no-results>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Hit from "@/js/search/components/results/Hit";
import NoResults from "@/js/search/components/results/NoResults";

export default {
  components: {
    Hit,
    NoResults,
  },
  props: {
    innerClass: {
      type: [String, Array, Object],
      default: "",
    },
    outerClass: {
      type: [String, Array, Object],
      default: "",
    },
  },
  computed: {
    ...mapGetters(["hits", "totalHits"]),
    hasNoResults() {
      return this.totalHits === 0;
    },
  },
};
</script>
