<template>
  <div
    :style="{
      ...modalStyle,
      zIndex: 9999,
      backgroundColor: 'rgba(0,0,0, 0.75)',
    }"
  >
    <featured-search-wrapper
      :input-id="id('input')"
      wrapper-class="bg-grey"
      :form-attrs="state.siteSearchData.formAttrs"
      :label="state.siteSearchData.label"
    >
      <!-- do not remove .mousetrap, required for focus management -->
      <input
        :id="id('input')"
        ref="searchInput"
        v-bind="state.siteSearchData.queryInputAttrs"
        class="m-site-search__input mousetrap"
        required="required"
        type="text"
        @focus="bindShiftTab"
        @blur="unbindShiftTab"
      />
      <input
        v-for="(value, key) in state.siteSearchData.hiddenParams"
        :key="key"
        :name="key"
        :value="value"
        type="hidden"
      />
      <button
        :id="id('submit')"
        title="Submit search query"
        type="submit"
        class="m-site-search__btn"
      >
        <icon name="search" />
      </button>
      <template #after>
        <button
          :id="id('close')"
          type="button"
          title="Close search"
          class="m-site-search__btn-close u-text-color-black"
          @click="hide"
        >
          <icon name="close" />
        </button>
      </template>
    </featured-search-wrapper>
  </div>
</template>
<script>
import Mousetrap from "mousetrap";

import modalHelper from "@/js/vue-mixins/modalHelper";

import getUuid from "@/js/lib/uuid";

import { focusSubscribe, focusUnsubscribe, willAcceptFocus } from "@/js/helpers/focus-helper";

import FeaturedSearchWrapper from "@/js/search/components/FeaturedSearchWrapper";
import Icon from "@/components/atoms/icon/Icon";
import store, { actions } from "@/components/molecules/masthead/store";

const uuid = getUuid();

export default {
  components: {
    FeaturedSearchWrapper,
    Icon,
  },
  mixins: [modalHelper],
  data() {
    return {
      state: store.state,
    };
  },
  created() {
    this.$nextTick(() => {
      this.$refs.searchInput.focus();
      focusSubscribe(this.acceptFocus);
    });
  },
  beforeDestroy() {
    focusUnsubscribe(this.acceptFocus);
    this.unbindShiftTab();
  },
  methods: {
    ...actions,
    hide() {
      this.allowBodyScroll(!!this._bodyScrollSavedPosition);
      this.$emit("hide");
    },
    id: (prefix = "item") => `search-${prefix}-${uuid}`,
    acceptFocus(opts) {
      willAcceptFocus(opts, {
        trigger: "searchButton",
        target: this.$refs.searchInput,
      });
    },
    bindShiftTab() {
      Mousetrap.bind("shift+tab", (nativeEvent) => {
        nativeEvent.preventDefault();
      });
    },
    unbindShiftTab() {
      this.$nextTick(() => Mousetrap.unbind("shift+tab"));
    },
  },
};
</script>
