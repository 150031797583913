<template>
  <form class="m-course-compare__tray" :action="state.compareFormConfig.action" method="POST">
    <div class="bg-primary p u-text-white">
      <div class="g align-apart align-v-center">
        <div class="g__col">
          <h2 class="bg-primary h3 mg--reset u-text-white">Course Compare</h2>
        </div>
        <div class="g__col">
          <icon name="book" :height="20" :width="24" />
        </div>
      </div>
    </div>
    <div class="p m-course-compare__list">
      <transition-expand>
        <div v-if="hasTooFewSelectedCourses" class="m-course-compare__feedback">
          <p v-if="requiresManualSelection" class="m-course-compare__feedback-msg">
            Select between {{ state.minComparableItemCount }} and
            {{ state.maxComparableItemCount }} courses to begin&nbsp;comparision
          </p>
          <p v-else class="m-course-compare__feedback-msg">
            Add at least one more course to begin&nbsp;comparision
          </p>
        </div>
      </transition-expand>
      <transition-group-expand>
        <course-compare-item
          v-for="course in savedCourses"
          :key="course.CourseGuid"
          :course="course"
          :has-checkbox="useCheckboxes"
          :is-selected="!requiresManualSelection || course.isSelected"
          :is-selectable="isSelectable(course)"
        />
      </transition-group-expand>
      <button
        :disabled="!canCompare"
        type="submit"
        class="btn btn--block btn--primary m-course-compare__submit"
      >
        Compare <span class="sr-only">courses</span>
      </button>
    </div>
  </form>
</template>
<script>
import Icon from "@/components/atoms/icon/Icon";
import CourseCompareItem from "./CourseCompareItem";
import TransitionExpand from "./transitions/TransitionExpand";
import TransitionGroupExpand from "./transitions/TransitionGroupExpand";

import store, { queries } from "../store";

export default {
  components: {
    CourseCompareItem,
    Icon,
    TransitionExpand,
    TransitionGroupExpand,
  },
  data() {
    return store;
  },
  computed: {
    useCheckboxes() {
      return this.requiresManualSelection;
    },
  },
  methods: {
    ...queries,
  },
};
</script>
<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition-duration: 0.25s;
}
</style>
