<template>
  <div
    class="shadow island island--white"
    :class="{
      'is--fixed shadow': isFixed,
      'is--off-canvas-top': isFixed && !isShown,
    }"
  >
    <div class="wrapper" :data-fixednav-container="isFixed" style="overflow-y: hidden;">
      <nav class="m-nav m-nav--horizontal">
        <a
          v-for="navItem in navItems"
          :key="navItem.id"
          :href="'#' + navItem.id"
          :class="{ 'nav-item--primary': !isActive(navItem) }"
          class="nav-item u-text-left"
          @click.prevent="scrollToSection(navItem)"
        >
          <span>{{ navItem.title }}</span>
        </a>
      </nav>
    </div>
  </div>
</template>
<script>
import InPageNavBase from "./InPageNavBase";

export default {
  extends: InPageNavBase,
};
</script>
