<template>
  <input
    :id="id"
    :class="className"
    type="search"
    :placeholder="placeholder"
    :value="query"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="true"
    @input="setQuery"
    @focus="onFocus"
    @blur="onBlur"
    @submit.stop.prevent
    @keydown.enter.stop.prevent
  />
</template>
<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

import { SET_QUERY, SET_QUERY_FOCUS } from "@/js/search/store/mutations";

export default {
  props: {
    placeholder: {
      type: String,
      default: "e.g. Nursing, Finance, Journalism...",
    },
    id: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "input",
    },
  },
  data() {
    return {
      setQuery: () => {},
    };
  },
  computed: {
    ...mapState({
      query: ({ search }) => search.query,
    }),
    debounceAmount() {
      return this.isTouchDevice ? 1500 : 300;
    },
    isTouchDevice() {
      return (
        "ontouchstart" in window ||
        window.navigator.MaxTouchPoints > 0 ||
        window.navigator.msMaxTouchPoints > 0
      );
    },
  },
  created() {
    this.setQuery = debounce(this._setQuery, this.debounceAmount);
  },
  methods: {
    _setQuery(e) {
      this.$store.commit(SET_QUERY, e.target.value.trim());

      this.$emit("change");
    },
    resetQuery() {
      this.$store.commit(SET_QUERY, "");
    },
    onFocus() {
      this.$store.commit(SET_QUERY_FOCUS, true);
    },
    onBlur() {
      this.$store.commit(SET_QUERY_FOCUS, false);
    },
  },
};
</script>
