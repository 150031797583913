import Vue from "vue";

function WatcherFactory(watchedKeys, cb) {
  return watchedKeys.reduce((watchers, key) => {
    return {
      ...watchers,
      [`state.${key}`]: {
        deep: true,
        handler(newValue, oldValue) {
          this[cb](key, newValue, oldValue);
        },
      },
    };
  }, {});
}

export default (store, { persistedKeys, prefix }) => {
  const getKeyName = (key) => `${prefix}.${key}`;

  const storage = {
    setItem(key, value) {
      return window.localStorage.setItem(getKeyName(key), value);
    },
    getItem(key) {
      return window.localStorage.getItem(getKeyName(key));
    },
  };

  return new Vue({
    data() {
      return store;
    },
    computed: {
      keyNames() {
        return persistedKeys.reduce((result, key) => {
          return {
            ...result,
            [getKeyName(key)]: key,
          };
        }, {});
      },
    },
    created() {
      window.addEventListener("storage", this.handleLocalStorageChange);
    },
    beforeDestroy() {
      window.removeEventListener("storage", this.handleLocalStorageChange);
    },
    methods: {
      write(key, newValue) {
        storage.setItem(key, JSON.stringify(newValue));
      },
      read(key) {
        return JSON.parse(storage.getItem(key));
      },
      readAll(keys) {
        return keys.reduce((result, key) => {
          return {
            [key]: this.read(key),
            ...result,
          };
        }, {});
      },
      handleLocalStorageChange({ key, newValue, oldValue, url }) {
        if (Object.keys(this.keyNames).includes(key)) {
          this.$emit("change", { key: this.keyNames[key], newValue, oldValue, url });
        }
      },
    },
    watch: {
      ...WatcherFactory(persistedKeys, "write"),
    },
  });
};
