import Vue from "vue";

import breakpointState from "@/js/helpers/breakpoint-helper";

import FaqQuestions from "./vue-components/FaqQuestions";
import FaqInlineAnswer from "./vue-components/FaqInlineAnswer";
import FaqModalAnswer from "./vue-components/FaqModalAnswer";

export default (config) =>
  config.map((c) => {
    const questionsContainer = document.querySelector(c.questionsContainer);
    const answerContainer = document.querySelector(c.answerContainer);

    if (!questionsContainer) {
      throw new Error("Cannot find questions container for FAQs");
    }
    if (!answerContainer) {
      throw new Error("Cannot find anser container for FAQs");
    }

    /**
     * Extract the Q&A text content from the DOM
     * @return {Array}            Questions for Vue
     */
    const questions = [...questionsContainer.querySelectorAll(c.questionSelector)].map((qEl, i) => {
      const targetId = qEl.href.substring(qEl.href.indexOf("#") + 1);

      return {
        id: i + 1,
        question: qEl.innerText.trim(),
        answer: document.getElementById(targetId).innerHTML.trim(),
      };
    });

    /**
     * Shared state
     * @type {Object}
     */
    const store = {
      questions,
      breakpoints: breakpointState(),
      currentQuestion: null,
      initialItems: c.initialItems,
    };

    /**
     * The question list component
     */
    const container = new Vue({
      el: c.questionsContainer,
      name: "FaqQuestionsContainer",
      data: store,
      methods: {
        onShow(questionId) {
          this.currentQuestion = this.questions.find((q) => q.id === questionId);
        },
        onHide() {
          this.currentQuestion = null;
        },
      },
      render(h) {
        return h(FaqQuestions, {
          props: {
            questions: this.questions,
            defaultQuestion: this.questions[0],
            currentQuestion: this.currentQuestion,
            initialItems: this.initialItems,
            breakpoints: this.breakpoints,
          },
          on: {
            show: (e) => this.onShow(e),
            hide: () => this.onHide(),
          },
        });
      },
    });

    /**
     * The answer inline in the page
     */
    const inlineAnswer = new Vue({
      el: c.answerContainer,
      name: "InlineAnswer",
      data: store,
      render(h) {
        return h(FaqInlineAnswer, {
          props: {
            currentQuestion: this.currentQuestion,
            defaultQuestion: this.questions[0],
          },
        });
      },
    });

    /**
     * The modal answer
     */
    const modalAnswer = new Vue({
      name: "ModalAnswer",
      data: store,
      methods: {
        onHide() {
          this.currentQuestion = null;
        },
      },
      render(h) {
        return h(FaqModalAnswer, {
          props: {
            currentQuestion: this.currentQuestion,
            breakpoints: this.breakpoints,
          },
          on: {
            hide: () => this.onHide(),
          },
        });
      },
    }).$mount();

    const modalEl = document.createElement("div");
    modalEl.classList.add("shu-design-system-v1-0");

    document.body.appendChild(modalEl).appendChild(modalAnswer.$el);

    return {
      config,
      container,
      inlineAnswer,
      modalAnswer,
      questions,
      store,
    };
  });
