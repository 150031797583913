<script>
import { mapGetters, mapState } from "vuex";

import YearOfEntry from "@/js/search/components/query/YearOfEntry";
import CheckboxFacet from "@/js/search/components/facets/CheckboxFacet";
import MultiboxFacet from "@/js/search/components/facets/MultiboxFacet";
import SingleCheckboxFacet from "@/js/search/components/facets/SingleCheckboxFacet";
import FilterButtonMobile from "@/js/search/components/filters/FilterButtonMobile";
import SearchBox from "@/js/search/components/query/SearchBox";
import ResetAllFilters from "@/js/search/components/filters/ResetAllFilters";

const valueByPath = (obj, path) =>
  path.split(".").reduce((acc, part) => (acc ? acc[part] : undefined), obj);

export default {
  components: {
    FilterButtonMobile,
    CheckboxFacet,
    MultiboxFacet,
    SingleCheckboxFacet,
    ResetAllFilters,
    SearchBox,
    YearOfEntry,
  },
  computed: {
    ...mapGetters(["filtersForUi"]),
    ...mapState({
      config: ({ ui }) => ui.config,
    }),
  },
  methods: {
    shouldShowFacet(facet) {
      if (facet.condition) {
        if (facet.condition === true) {
          return true;
        }
        if (facet.condition === false) {
          return false;
        }
        if (typeof facet.condition === 'function') {
          return facet.condition(this.config)
        }
        return valueByPath(this.config, facet.condition);
      }
      const facetWithValues = this.filtersForUi.all.find((f) => f.name === facet.name);

      return !(!facetWithValues || !facetWithValues.values.length);
    },
  },
};
</script>
