<template>
  <div class="select__wrapper">
    <select aria-label="Select a page" class="select select--grey" @change.prevent="handleChange">
      <option v-for="page in range" :key="page" :value="page - 1" :selected="page === pageForUi"
        ><span class="label">{{ page }}</span></option
      >
    </select>

    <span class="select__icon"></span>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { range } from "lodash";

export default {
  computed: {
    ...mapGetters(["pageForUi", "totalPages"]),
    range() {
      return range(1, this.totalPages + 1);
    },
  },
  methods: {
    ...mapActions(["setPage"]),
    handleChange({ target }) {
      this.setPage({ page: parseInt(target.value, 10) });
    },
  },
};
</script>
