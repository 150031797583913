<template>
  <div>
    <div class="g">
      <div class="g__col g__col-12">
        <year-of-entry />
      </div>
    </div>
    <hr class="hr hr--dark hr--xsmall" />
    <div class="g">
      <div
        v-for="facet in filtersForUi.main.filter(facet => shouldShowFacet(facet))"
        :key="facet.name"
        class="g__col g__col-12 g__col-6@medium g__col-4@large p-bottom--double p-bottom--reset@medium"
      >
        <component :is="facet.type" :facet="facet" />
        <hr class="hr hr--dark hr--xsmall is--hidden@medium" />
      </div>
      <div class="g__col g__col-12 g__col-4@large p-bottom--double p-bottom--reset@medium">
        <h4 class="m-accordion__title-text">More options</h4>
        <div class="g mg-top--half">
          <div
            v-for="(facet, index) in filtersForUi.additional.filter(facet => shouldShowFacet(facet))"
            :key="facet.name"
            class="g__col g__col-12 g__col-6@medium g__col-12@large"
          >
            <component :is="facet.type" :facet="facet" />
            <hr
              v-if="index < filtersForUi.additional.filter(facet => shouldShowFacet(facet)).length - 1"
              class="hr hr--dark hr--xsmall is--hidden@medium"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FiltersBase from "@/js/search/components/filters/FiltersBase";

export default {
  extends: FiltersBase,
};
</script>
