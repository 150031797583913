/* eslint-disable no-param-reassign */
// import mockHits from '@/mocks/hits.json';
import Vue from "vue";
import { debounce, pick } from "lodash";

import SearchError from "@/js/search/errors/SearchError";

import reverseDictionary from "@/js/lib/reverseDictionary";

import {
  CLEAR_FACET,
  CLEAR_SEARCH,
  SET_AVAILABLE_YEARS_OF_ENTRY,
  SET_DEFAULT_YEAR_OF_ENTRY,
  SET_ENFORCED_SUBJECT_AREA,
  SET_HAS_SOLR_CLIENT,
  SET_HITS_PER_PAGE,
  SET_PAGE,
  SET_QUERY,
  SET_RANGE_REFINEMENT,
  SET_RESULTS,
  SET_SEARCH_FACET_VALUE,
  SET_SEARCH_LINK_QS,
  SET_DEFAULT_SEARCH_URL,
  SET_SOLR_AVAILABLE,
  SET_SOLR_CONFIG,
  SET_SUBJECT_AREAS,
  SET_UI_LABELS,
  SET_USE_URL_PARAM_STATE,
  SET_YEAR_OF_ENTRY,
  TOGGLE_SEARCH_FACET_VALUE,
  UNSET_SEARCH_FACET_VALUE,
  UNSET_SUBJECT_AREAS,
} from "@/js/search/store/mutations";

import { FACETS } from "@/js/search/constants/facets";

import { arrayToggle, arrayWith, arrayWithout } from "@/js/search/store/helpers/arrayHelper";

import { facetsForSearch, facetsForQs } from "@/js/search/store/helpers/facetHelper";

const facets = {
  ...facetsForSearch(FACETS),
};

export default {
  state: {
    query: "",

    indexName: "",
    solrBaseUrl: "",
    solrDictionary: null,
    reverseDictionary: null,

    perPage: 10,
    page: 0,

    useUrlParamState: true,
    searchLinkQs: ``,

    enforcedSubjectArea: null,
    subjectAreas: [],

    defaultSearchUrl: "/courses",

    availableYearsOfEntry: [],
    yearOfEntry: "",
    defaultYearOfEntry: "",
    hasSolrClient: false,
    solrAvailable: false,
    facets,
    config: {},
    hits: [],
    nbHits: 0,
    uiLabels: {
      NO_LONGER_RECRUITING: "Course full",
      COURSE_IN_CLEARING: "Course in clearing",
    },
  },
  mutations: {
    [SET_QUERY](state, query) {
      state.query = query.replace(/[^\w\s]/gi, "");
      state.page = 0;
    },
    [SET_SUBJECT_AREAS](state, subjectAreas = [], opts = {}) {
      opts = {
        resetPage: true,
        ...opts,
      };

      if (opts.resetPage) {
        state.page = 0;
      }

      if (!Array.isArray(subjectAreas)) {
        subjectAreas = [subjectAreas];
      }

      subjectAreas.forEach((subjectArea) => {
        if (!state.solrDictionary[subjectArea]) {
          throw new SearchError(`Unknown subjectArea ${subjectArea}`);
        }
        state.subjectAreas = arrayWith(state.subjectAreas, state.solrDictionary[subjectArea]);
      });
    },
    [UNSET_SUBJECT_AREAS](state, subjectAreas = [], opts = {}) {
      opts = {
        resetPage: true,
        ...opts,
      };

      if (opts.resetPage) {
        state.page = 0;
      }

      if (!Array.isArray(subjectAreas)) {
        subjectAreas = [subjectAreas];
      }

      subjectAreas.forEach((subjectArea) => {
        if (!state.solrDictionary[subjectArea]) {
          throw new SearchError(`Unknown subjectArea ${subjectArea}`);
        }
        state.subjectAreas = arrayWithout(state.subjectAreas, state.solrDictionary[subjectArea]);
      });
    },
    [SET_SEARCH_LINK_QS](state, qs) {
      state.searchLinkQs = qs;
    },
    [SET_DEFAULT_SEARCH_URL](state, url) {
      state.defaultSearchUrl = url;
    },
    [SET_ENFORCED_SUBJECT_AREA](state, guid) {
      state.enforcedSubjectArea = guid;
    },
    [SET_AVAILABLE_YEARS_OF_ENTRY](state, years) {
      if (!state.solrDictionary) {
        throw new Error("Cannot set years of entry without dictionary");
      }
      state.availableYearsOfEntry = years.map((y) => state.solrDictionary[y]);
    },
    [CLEAR_SEARCH](state) {
      state.query = "";
      if (state.defaultYearOfEntry) {
        state.yearOfEntry = state.defaultYearOfEntry;
      }
      state.subjectAreas = [];

      FACETS.forEach(({ name }) => {
        Vue.set(state.facets, name, []);
      });
    },
    [SET_SOLR_CONFIG](state, { dictionary, baseUrl, indexName }) {
      state.solrBaseUrl = baseUrl;
      state.indexName = indexName;
      state.solrDictionary = dictionary;
      state.reverseDictionary = reverseDictionary(dictionary);
    },
    [SET_YEAR_OF_ENTRY](state, value) {
      state.yearOfEntry = value;
      state.page = 0;
    },
    [SET_DEFAULT_YEAR_OF_ENTRY](state, value) {
      state.defaultYearOfEntry = value;
    },
    [SET_PAGE](state, page) {
      state.page = parseInt(page, 10);
    },
    [SET_HITS_PER_PAGE](state, perPage) {
      state.perPage = parseInt(perPage, 10);
      state.page = 0;
    },
    [SET_SEARCH_FACET_VALUE](state, { name, value }) {
      Vue.set(state.facets, name, arrayWith(state.facets[name], value));

      state.page = 0;
    },
    [UNSET_SEARCH_FACET_VALUE](state, { name, value }) {
      Vue.set(state.facets, name, arrayWithout(state.facets[name], value));

      state.page = 0;
    },
    [TOGGLE_SEARCH_FACET_VALUE](state, { name, value }) {
      Vue.set(state.facets, name, arrayToggle(state.facets[name], value));

      state.page = 0;
    },
    [SET_RANGE_REFINEMENT](state, { name, values }) {
      Vue.set(state.facets, name, values);
      state.page = 0;
    },
    [CLEAR_FACET](state, name) {
      Vue.set(state.facets, name, []);
      state.page = 0;
    },
    [SET_RESULTS](state, { hits, nbHits }) {
      state.hits = hits;
      state.nbHits = nbHits;
    },
    [SET_SOLR_AVAILABLE](state, isAvailable) {
      state.solrAvailable = isAvailable;
    },
    [SET_HAS_SOLR_CLIENT](state, hasClient) {
      state.hasSolrClient = hasClient;
    },
    [SET_USE_URL_PARAM_STATE](state, useUrlParamState) {
      state.useUrlParamState = useUrlParamState;
    },
    [SET_UI_LABELS](state, labels) {
      Object.entries(labels).forEach(([name, value]) => {
        state.uiLabels[name] = value;
      });
    },
  },
  getters: {
    query: (state) => state.query,
    dictionary: (state) => state.solrDictionary,
    integerStateKeys: () => ["page", "perPage", "nbHits"],
    hasSearched: (state) => {
      let hasSearched = false;

      if (state.query.length) {
        hasSearched = true;
      } else {
        Object.keys(state.facets).forEach((f) => {
          if (state.facets[f].length) {
            hasSearched = true;
          }
        });
      }
      return hasSearched;
    },
    hits: ({ hits }) => hits,
    hitsOnPage: ({ hits }) => hits.length,
    totalHits: ({ nbHits }) => nbHits,

    firstHitOnPage: ({ perPage }, { page }) => 1 + page * perPage,
    lastHitOnPage: (state, { firstHitOnPage, hitsOnPage }) => firstHitOnPage + (hitsOnPage - 1),
    facetRefinements: (state) => {
      return Object.values(state.facets).reduce((acc, values) => acc.concat(values), []);
    },
    page: (state) => state.page,
    pageForUi: (state) => state.page + 1,
    totalPages({ nbHits, perPage }) {
      return Math.ceil(nbHits / perPage);
    },
    hasPrevPage: (state, { pageForUi }) => pageForUi > 1,
    hasNextPage: (state, { pageForUi, totalPages }) => pageForUi < totalPages,

    hasRefinements: (state) => {
      let isRefined = false;

      if (state.query.length) {
        isRefined = true;
      } else {
        FACETS.forEach(({ name }) => {
          if (state.facets[name].length) {
            isRefined = true;
          }
        });
        if (state.yearOfEntry !== state.defaultYearOfEntry) {
          isRefined = true;
        }
      }

      return isRefined;
    },
    enforcedSubjectAreaName({ enforcedSubjectArea, solrDictionary }) {
      return solrDictionary[enforcedSubjectArea];
    },
    yearOfEntryForUi({ yearOfEntry }) {
      return {
        value: yearOfEntry,
        label: yearOfEntry,
      };
    },
    availableYearsOfEntryForUi(state) {
      return state.availableYearsOfEntry.map((yearOfEntry) => {
        return {
          value: yearOfEntry,
          label: yearOfEntry,
        };
      });
    },
    searchStateForQs(state) {
      return {
        ...pick(state, ["page", "perPage", "query", "subjectAreas", "yearOfEntry"]),
        ...facetsForQs(FACETS, state),
      };
    },
    searchStateForLinkQs(state) {
      return {
        ...pick(state, ["query"]),
        ...facetsForQs(FACETS, state),
      };
    },
  },
  actions: {
    preloadQuery({ state, commit }, config) {
      const { SUBJECT_AREA, YEAR_OF_ENTRY, IN_CLEARING, COURSE_TYPE } = config;

      if (SUBJECT_AREA) {
        commit(SET_ENFORCED_SUBJECT_AREA, SUBJECT_AREA);
      }
      if (YEAR_OF_ENTRY && !state.yearOfEntry) {
        const yearOfEntry = state.solrDictionary[YEAR_OF_ENTRY];

        commit(SET_DEFAULT_YEAR_OF_ENTRY, yearOfEntry);
        commit(SET_YEAR_OF_ENTRY, yearOfEntry);
      }
      if (COURSE_TYPE) {
        commit(SET_SEARCH_FACET_VALUE, { name: "coursetype", value: COURSE_TYPE });
      }

      if (IN_CLEARING) {
        commit(SET_SEARCH_FACET_VALUE, { name: "inclearing", value: true });
      }

      // @tooo load full query
    },
    setSearchState({ state, commit }, inputState) {
      Object.entries(inputState).forEach(function processInput([name, value]) {
        if (state.facets[name]) {
          return value.forEach((v) => commit(SET_SEARCH_FACET_VALUE, { name, value: v }));
        }

        let mutation;

        switch (name) {
          case "query":
            mutation = SET_QUERY;
            break;
          case "perPage":
            mutation = SET_HITS_PER_PAGE;
            break;
          case "page":
            mutation = SET_PAGE;
            break;
          case "yearOfEntry":
            mutation = SET_YEAR_OF_ENTRY;
            break;
          case "subjectAreas":
            mutation = SET_SUBJECT_AREAS;

            if (!Array.isArray(value)) {
              value = [value];
            }

            value = value.map((v) => state.reverseDictionary[v]);
            break;
          default:
            break;
        }

        if (mutation && state[name] !== value) {
          return commit(mutation, value);
        }
        return false;
      });
    },
    setPage: debounce(({ commit, rootGetters }, { page }) => {
      page = parseInt(page, 10);

      if (page > rootGetters.totalPages - 1) {
        page = rootGetters.totalPages - 1;
      }
      if (page <= 0) {
        page = 0;
      }

      return commit(SET_PAGE, page);
    }, 100),
    setResults({ commit }, payload) {
      commit(SET_RESULTS, payload);
      return true;
    },
    pageNext({ dispatch, state }) {
      dispatch("setPage", { page: state.page + 1 });
    },
    pagePrev({ dispatch, state }) {
      dispatch("setPage", { page: state.page - 1 });
    },
  },
};
