<template>
  <div class="g">
    <div class="g__col g__col-12">
      <button class="btn btn--primary btn--block wrapper" @click="toggleMobileFilters">
        {{ text }}
      </button>
    </div>
  </div>
</template>
<script>
import { TOGGLE_MOBILE_FILTERS } from "@/js/search/store/mutations";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["totalHits", "showMobileFilters"]),
    text() {
      return this.showMobileFilters
        ? `Show ${this.totalHits} results`
        : `Filter ${this.totalHits} results`;
    },
  },
  methods: {
    toggleMobileFilters() {
      this.$store.commit(TOGGLE_MOBILE_FILTERS);
    },
  },
};
</script>
