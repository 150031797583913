<template>
  <fieldset v-on-clickaway="close" class="multi-select" :aria-labelledby="`${id}FieldsetHeader`">
    <legend class="visually-hidden">{{ label }}</legend>
    <button 
      aria-haspopup="listbox" 
      :aria-expanded="active.toString()"
      aria-controls="subjectList"
      class="multi-select__button" 
      type="button" 
      @click="toggle">
      <template v-if="selectedOptions.length === 1"> 1 selected </template>
      <template v-else-if="selectedOptions.length === 0">Select subjects</template>
      <template v-else> {{ selectedOptions.length }} selected </template>
      <span class="select__icon"></span>
    </button>
    
    <div id="subjectList">
      <div
        v-if="active && (autocomplete || selectedOptions.length > 0)"
        class="multi-select__selected_container multi-select__dropdown">
        <form-multi-select-row
          v-for="item in selectedOptions"
          :id="id"
          :value="item.value"
          :key="item.value"
          :checked="item.checked"
          :label="item.label"
          @update:checked="check(item.value, $event)"
        />
      </div>
      <div v-if="active" class="multi-select__dropdown">
        <form-multi-select-row
          v-for="item in displayOptions"
          :id="id"
          :value="item.value"
          :key="item.value"
          :checked="item.checked"
          :disabled="selectedOptions.length >= max"
          :label="item.label"
          :search="query"
          @update:checked="check(item.value, $event)"
        />
      </div>
    </div>
  </fieldset>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import FormMultiSelectRow from "@/js/search/components/form-controls/FormMultiSelectRow.vue";

export default {
  components: {
    FormMultiSelectRow,
  },
  directives: {
    onClickaway,
  },
  props: {
    defaultText: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 3,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: "",
      active: false,
      isMultilistOpen: false
    };
  },
  computed: {
    displayOptions() {

      const unselectedOptions = this.options.filter((option) => !option.checked);

      if (!this.autocomplete || !this.query) {
        return unselectedOptions;
      }

      return unselectedOptions.filter((option) => option.label.toLowerCase().includes(this.query.toLowerCase()));
    },
    selectedOptions() {
      return this.options.filter((option) => option.checked);
    },
  },
  methods: {
    close() {
      this.active = false;
    },
    toggle() {
      this.active = !this.active;
    },
    open() {
      this.active = true;
    },
    check(optionId, checked) {
      this.$emit("change", optionId, checked);
    }
  },
};
</script>
