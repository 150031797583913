// UI
export const SET_BREAKPOINT = "SET_BREAKPOINT";
export const SET_CONFIG = "SET_CONFIG";
export const SET_QUERY_FOCUS = "SET_QUERY_FOCUS";
export const STORE_QS = "STORE_QS";
export const TOGGLE_MOBILE_FILTERS = "TOGGLE_MOBILE_FILTERS";
export const SET_RESULTS_SHOWN = "SET_RESULTS_SHOWN";

// Search
export const SET_SOLR_CONFIG = "SET_SOLR_CONFIG";
export const SET_SOLR_AVAILABLE = "SET_SOLR_AVAILABLE";
export const SET_HAS_SOLR_CLIENT = "SET_HAS_SOLR_CLIENT";
export const SET_USE_URL_PARAM_STATE = "SET_USE_URL_PARAM_STATE";
export const SET_SEARCH_LINK_QS = "SET_SEARCH_LINK_QS";
export const SET_DEFAULT_SEARCH_URL = "SET_DEFAULT_SEARCH_URL";

export const SET_SUBJECT_AREAS = "SET_SUBJECT_AREAS";
export const SET_ENFORCED_SUBJECT_AREA = "SET_ENFORCED_SUBJECT_AREA";
export const UNSET_SUBJECT_AREAS = "UNSET_SUBJECT_AREAS";
export const SET_PAGE = "SET_PAGE";
export const SET_HITS_PER_PAGE = "SET_HITS_PER_PAGE";
export const SET_QUERY = "SET_QUERY";
export const SET_SEARCH_FACET_VALUE = "SET_SEARCH_FACET_VALUE";
export const TOGGLE_SEARCH_FACET_VALUE = "TOGGLE_SEARCH_FACET_VALUE";
export const UNSET_SEARCH_FACET_VALUE = "UNSET_SEARCH_FACET_VALUE";
export const SET_RANGE_REFINEMENT = "SET_RANGE_REFINEMENT";

export const SET_AVAILABLE_YEARS_OF_ENTRY = "SET_AVAILABLE_YEARS_OF_ENTRY";
export const SET_YEAR_OF_ENTRY = "SET_YEAR_OF_ENTRY";
export const SET_DEFAULT_YEAR_OF_ENTRY = "SET_DEFAULT_YEAR_OF_ENTRY";
export const SET_UI_LABELS = "SET_UI_LABELS";

// Results
export const SET_RESULTS = "SET_RESULTS";
// export const SET_SEARCH_STATE = 'SET_SEARCH_STATE';

// Facets
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CLEAR_FACET = "CLEAR_FACET";
export const CLEAR_FACET_VALUES = "CLEAR_FACET";
export const SET_FACET_VALUES = "SET_FACET_VALUES";
