import objectFitImages from "object-fit-images";
import "jspolyfill-array.prototype.findIndex";

import svg4everybody from "svg4everybody";

import objectPrototypeKeys from "./Object.prototype.keys";

import arrayPrototypeFind from "./Array.prototype.find";
import arrayPrototypeIncludes from "./Array.prototype.includes";
import arrayPrototypeFlat from "./Array.prototype.flat";

export default () =>
  new Promise((resolve) => {
    arrayPrototypeFind();
    arrayPrototypeFlat();
    arrayPrototypeIncludes();
    objectFitImages();
    objectPrototypeKeys();
    svg4everybody();
    resolve();
  });
