<template>
  <svg
    :class="className"
    :height="height"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
  >
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    height: {
      type: [Number, String],
      default: 20,
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconPath() {
      const id = `#icon-${this.name}`;
      return this.isBuild ? `/dist/img/icons.svg${id}` : id;
    },
    isBuild() {
      return process.env.NODE_ENV === "production";
    },
    className() {
      return `svg-icon svg-icon--${this.name}`;
    },
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
</script>
