<template>
  <div>
    <div class="m-self-selection__item">
      <div class="m-self-selection__question">
        <h3>{{ question }}</h3>
      </div>

      <div class="m-self-selection__answer btn-group">
        <button
          v-for="(answer, idx) in answerKeys"
          :key="idx"
          class="btn btn--small"
          :class="{ 'btn--primary': isActive(answers[answer]) }"
          @click.prevent="selectAnswer(answer)"
        >
          {{ answer }}
        </button>
      </div>
    </div>
    <transition name="slide-down-fade" appear>
      <question
        v-if="childAnswer"
        :question="childAnswer.question"
        :answers="childAnswer.answers"
        @resolve="resolve"
        @desolve="desolve"
      ></question>
    </transition>
  </div>
</template>
<script>
export default {
  name: "Question",
  props: {
    question: {
      type: String,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedAnswer: null,
    };
  },
  computed: {
    answerKeys() {
      return Object.keys(this.answers);
    },
    childAnswer() {
      if (this.selectedAnswer && !this.selectedAnswer.resolve) {
        return this.selectedAnswer;
      }
      return null;
    },
  },
  watch: {
    selectedAnswer(newAnswer) {
      if (newAnswer.resolve) {
        this.resolve(newAnswer.resolve);
      } else {
        this.desolve();
      }
    },
  },
  methods: {
    isActive(answer) {
      return answer === this.selectedAnswer;
    },
    selectAnswer(answer) {
      this.selectedAnswer = this.answers[answer];
    },
    resolve(value) {
      this.$emit("resolve", value);
    },
    desolve() {
      this.$emit("desolve");
    },
  },
};
</script>
