import Vue from "vue";

import getBreakpoints from "@/js/helpers/breakpoint-helper";

import Modal from "./vue-components/Modal";

// @todo make work with multiple modals

export const mapValueWatchers = ({ property, defaultValue, watchMap }) => {
  return Object.keys(watchMap.values).reduce((acc, key) => {
    acc[`${watchMap.prefix}${key}`] = function watcher(newValue) {
      this[property] = newValue ? watchMap.values[key] : defaultValue;
    };
    return acc;
  }, {});
};

export default (config) => {
  return config.map((c) => {
    const breakpointState = getBreakpoints();
    const enabledByDefault = typeof c.enabled === "boolean" ? c.enabled : true;
    const enableBreakpoints = c.breakpoints || {};

    /**
     * Shared state
     */
    const store = {
      isEnabled: enabledByDefault,
      isShown: false,
      breakpoints: breakpointState,
      modalContent: document.querySelector(c.contentContainer).innerHTML,
      modalContentClass: c.contentClassName || "",
    };

    /**
     * Trigger
     * @type {Element}
     */
    const trigger = document.querySelector(c.triggerSelector);

    trigger.addEventListener("click", () => {
      store.isShown = true;
    });

    /**
     * Manager
     *
     * Handle enabling / disabling the modal at breakpoints (if registered)
     * and cleaning up when changes occur
     */
    const manager = new Vue({
      name: "ModalManager",
      data: store,
      watch: {
        ...mapValueWatchers({
          property: "isEnabled",
          defaultValue: enabledByDefault,
          watchMap: {
            values: enableBreakpoints,
            prefix: "breakpoints.",
          },
        }),
        isEnabled(isNowEnabled) {
          if (!isNowEnabled && this.isShown) {
            this.isShown = false;
          }
        },
      },
      created() {
        this.isEnabled = Object.keys(enableBreakpoints).reduce((acc, key) => {
          if (this.breakpoints[key]) {
            // eslint-disable-next-line no-param-reassign
            acc = enableBreakpoints[key];
          }
          return acc;
        }, {});
      },
    });

    /**
     * The modal
     */
    const modal = new Vue({
      name: "ModalContainer",
      data: store,
      methods: {
        onHide() {
          this.isShown = false;
        },
      },
      render(h) {
        return h(Modal, {
          props: {
            content: this.modalContent,
            isShown: this.isShown,
            innerClass: this.innerClass,
            className: this.contentClassName,
          },
          on: {
            hide: () => this.onHide(),
          },
        });
      },
    }).$mount();

    const modalEl = document.createElement("div");
    modalEl.classList.add("shu-design-system-v1-0");

    document.body.appendChild(modalEl).appendChild(modal.$el);

    return {
      config: c,
      store,
      modal,
      manager,
      trigger,
    };
  });
};
