import Vue from "vue";
import SelfSelection from "./vue-components/SelfSelection";
import CallToActions from "./vue-components/CallToActions";

export default (config) =>
  config.map((c) => {
    let bottomCtas;

    const defaultResult = document.querySelector(c.defaultResult).innerHTML;

    const results = Object.keys(c.results).reduce((acc, key) => {
      acc[key] = document.querySelector(c.results[key]).innerHTML.trim();
      return acc;
    }, {});

    results.default = defaultResult;

    const initialResultKey = null;
    // let initialResultKey = localStorage.getItem('clearingCtaResult');

    const store = {
      results,
      resultKey: initialResultKey,
      result: results[initialResultKey] || defaultResult,
      userHasInteracted: false,
    };

    const questions = new Vue({
      el: c.questionTarget,
      name: "SelfSelectionQuestions",
      data: store,
      methods: {
        onResolve(result) {
          this.resultKey = result;
          this.userHasInteracted = true;
          // localStorage.setItem('clearingCtaResult', result);
        },
        onDesolve() {
          this.resultKey = null;
          this.userHasInteracted = true;
          // localStorage.setItem('clearingCtaResult', 'default');
        },
      },
      render(h) {
        return h(SelfSelection, {
          props: {
            opts: c.opts,
          },
          on: {
            resolve: (e) => this.onResolve(e),
            desolve: () => this.onDesolve(),
          },
        });
      },
    });

    const ctaConfig = {
      name: "ctaTarget",
      el: c.ctaTarget,
      data: store,
      render(h) {
        return h(CallToActions, {
          props: {
            results: this.results,
            resultKey: this.resultKey,
            userHasInteracted: this.userHasInteracted,
          },
        });
      },
    };

    const topCtas = new Vue({ ...ctaConfig, el: c.ctaTarget });

    if (document.querySelector(c.ctaBottomTarget)) {
      bottomCtas = new Vue({ ...ctaConfig, el: c.ctaBottomTarget });
    }

    return {
      questions,
      topCtas,
      bottomCtas,
      config,
      store,
    };
  });
