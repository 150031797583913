<template>
  <div v-if="showResults && totalPages" class="m-pagination">
    <page-prev />
    <span class="label">Page</span>
    <page-select style="margin-left: 5px; margin-right: 5px;" />
    <span class="label">&nbsp;of&nbsp;{{ totalPages }}</span>
    <page-next />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PageNext from "@/js/search/components/pagination/PageNext";
import PagePrev from "@/js/search/components/pagination/PagePrev";
import PageSelect from "@/js/search/components/pagination/PageSelect";

export default {
  components: {
    PageNext,
    PagePrev,
    PageSelect,
  },
  computed: {
    ...mapGetters(["showResults", "totalPages"]),
  },
};
</script>
