<template>
  <div v-if="heading" class="m-page-component--inpage">
    <div class="u-trim">
      <h2>{{ heading }}</h2>
    </div>
    <div class="g align-v-center">
      <div class="g__col g__col-12 g__col-7@large g__col-8@xlarge u-trim u-trim-pad">
        <p v-if="showResults">
          <result-count />
          <template v-if="enforcedSubjectAreaName">
            in <strong>{{ enforcedSubjectAreaName }}</strong>
          </template>
          <template v-else-if="subjectAreas.length">
            in
            <subject-area-pill
              v-for="(subjectArea, index) in subjectAreas"
              :key="subjectArea + index"
              :subject-area="subjectArea"
              class="m-search-title__subjects"
            />
          </template>
          <template v-else> across <strong>all subjects</strong> </template>
        </p>
      </div>
      <div
        class="g__col g__col-12 g__col-6@small g__col-auto@medium g__col-4@xlarge align-right u-text-right is--hidden is--visible@large"
      >
        <div class="g">
          <pager class="g__col align-right u-text-right" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

import Pager from "@/js/search/components/pagination/Pager";
import ResultCount from "@/js/search/components/results/ResultCount";
import SubjectAreaPill from "@/js/search/components/results/SubjectAreaPill";

export default {
  components: {
    Pager,
    ResultCount,
    SubjectAreaPill,
  },
  computed: {
    ...mapGetters(["showResults", "enforcedSubjectAreaName"]),
    ...mapState({
      subjectAreas: ({ search }) => search.subjectAreas,
      heading: ({ ui }) => ui.config.HEADING,
    }),
  },
};
</script>
<style scoped>
.m-search-title__subjects {
  position: relative;
  top: -2px;
  margin-right: 5px;
}
</style>
