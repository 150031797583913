<template>
  <button
    :title="title"
    :class="{ 'pill--icon-right': isApplied }"
    class="pill pill--grey"
    @click.prevent.stop="toggleSubject"
  >
    {{ subject }}<span v-if="isApplied" class="pill__close" role="presentation"></span>
  </button>
</template>
<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

import { SET_SUBJECT_AREAS, UNSET_SUBJECT_AREAS } from "@/js/search/store/mutations";

export default {
  props: {
    subject: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      toggleSubject: () => {},
    };
  },
  computed: {
    ...mapState({
      subjects: ({ search }) => search.subjectAreas,
      reverseDictionary: ({ search }) => search.reverseDictionary,
    }),
    subjectGuid() {
      return this.reverseDictionary[this.subject];
    },
    isApplied() {
      return this.subjects.includes(this.subject);
    },
    title() {
      return `${this.subject} (press to ${this.isApplied ? "remove" : "add"} subject filter)`;
    },
  },
  created() {
    this.toggleSubject = debounce(() => this._toggleSubject(), 50);
  },
  methods: {
    _toggleSubject() {
      return this.isApplied ? this.removeSubject() : this.addSubject();
    },
    removeSubject() {
      this.$store.commit(UNSET_SUBJECT_AREAS, this.subjectGuid);
    },
    addSubject() {
      this.$store.commit(SET_SUBJECT_AREAS, this.subjectGuid);
    },
  },
};
</script>
