<template>
  <div class="m-self-selection__results" :style="style">
    <template v-for="result in allResults">
      <call-to-action v-if="isCurrent(result)" :key="result.key" :html="result.html" />
    </template>
  </div>
</template>
<script>
import throttle from "lodash.throttle";
import CallToAction from "./CallToAction";

export default {
  components: {
    CallToAction,
  },
  props: {
    resultKey: {
      type: [String, null],
      required: false,
      default: null,
    },
    results: {
      type: Object,
      required: true,
    },
    userHasInteracted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      scrollTop: 0,
      hasScrolled: false,
      isTransitioning: false,
    };
  },
  computed: {
    isDefault() {
      return this.resultKey === "default" || !this.resultKey;
    },
    isScrolledToTop() {
      return this.scrollTop === 0;
    },
    style() {
      let opacity = 1;

      // Fade out completely when transitioning
      if (this.isTransitioning) {
        opacity = 0;
        // Default to knocked back is the user hasn't either interacted or scrolled yet
      } else if (!this.userHasInteracted && this.isDefault && this.isScrolledToTop) {
        opacity = 0.3;
        // Knock back if the user has iteracted but hasn't yet resolved to an audience
      } else if (this.userHasInteracted && this.isDefault) {
        opacity = 0.3;
      }

      return {
        opacity,
      };
    },
    allResults() {
      return Object.keys(this.results).map((k) => ({
        key: k,
        html: this.results[k],
      }));
    },
  },
  watch: {
    resultKey() {
      this.isTransitioning = true;

      this.$nextTick(() => {
        setTimeout(() => {
          this.isTransitioning = false;
        }, 500);
      });
    },
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.scrollHandler);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    isCurrent(result) {
      if (this.resultKey) {
        return result.key === this.resultKey;
      }
      return result.key === "default";
    },
    scrollHandler: throttle(
      function onScroll() {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

        this.scrollTop = scrollTop;
        this.hasScrolled = true;
      },
      100,
      { trailing: true }
    ),
  },
};
</script>
