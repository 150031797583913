<script>
import RenderExpandTransition from "./RenderExpandTransition";

export default {
  name: `TransitionGroupExpand`,
  functional: true,
  render: new RenderExpandTransition({ name: `expand`, tag: `div` }, `transition-group`),
};
</script>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style>
.expand-enter-active,
.expand-leave-active {
  transition: height 1s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    -webkit-animation: none !important;
  }
}
</style>
