<template>
  <div class="control">
    <label class="label label--spaced" for="year-of-entry">Show courses starting in:</label>
    <form-select
      id="year-of-entry"
      name="year-of-entry"
      :selected="yearOfEntryForUi"
      :options="availableYearsOfEntryForUi"
      @change="onChange"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { SET_YEAR_OF_ENTRY } from "@/js/search/store/mutations";

import FormSelect from "@/js/search/components/form-controls/FormSelect";

export default {
  components: {
    FormSelect,
  },
  computed: {
    ...mapGetters(["yearOfEntryForUi", "availableYearsOfEntryForUi"]),
  },
  methods: {
    onChange(newValue) {
      this.$store.commit(SET_YEAR_OF_ENTRY, newValue);
    },
  },
};
</script>
