<template>
  <div>
    <div class="mg-bottom--half">
      <h4 v-if="facet.displayTitle" class="m-accordion__title-text">{{ facet.displayTitle }}</h4>
    </div>
    <form-option-group :options="facet.values" type="checkbox" @change="onChange" />
  </div>
</template>
<script>
import { TOGGLE_SEARCH_FACET_VALUE } from "@/js/search/store/mutations";

import FormOptionGroup from "@/js/search/components/form-controls/FormOptionGroup";

export default {
  components: {
    FormOptionGroup,
  },
  props: {
    facet: {
      required: true,
      type: Object,
    },
  },
  methods: {
    onChange(facet) {
      this.$store.commit(TOGGLE_SEARCH_FACET_VALUE, facet);
    },
  },
};
</script>
