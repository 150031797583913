import Mousetrap from "mousetrap";

export default {
  mounted() {
    Mousetrap.bind("esc", () => this.hide());
  },
  beforeDestroy() {
    Mousetrap.unbind("esc");
  },
  data() {
    return {
      bodyScrollSavedPosition: null,
    };
  },
  computed: {
    scrollTop: () => window.pageYOffset || document.documentElement.scrollTop,
    modalStyle() {
      return {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "auto",
      };
    },
  },
  methods: {
    hide() {
      this.allowBodyScroll(!!this._bodyScrollSavedPosition);
      this.$emit("hide");
    },
    preventBodyScroll(savePosition = false) {
      document.body.style.overflow = "hidden";
      if (savePosition) {
        this.scrollPositionSave(this.scrollTop);
      }
    },
    allowBodyScroll(restorePosition = false) {
      document.body.style.overflow = "";
      if (restorePosition) {
        this.scrollPositionApply(this.bodyScrollSavedPosition);
      }
    },
    scrollPositionSave(position) {
      this.bodyScrollSavedPosition = position;
    },
    scrollPositionApply(position) {
      document.documentElement.scrollTop = position;
      document.body.scrollTop = position;
    },
  },
};
