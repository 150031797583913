<template>
  <div class="shu-design-system-v1-0">
    <modal :is-shown="showResults" class-name="m-modal--pretty" @hide="handleClose">
      <overlay-query />
      <summary-filters />
      <div>
        <div class="island island--grey u-trim p mg-top mg-bottom--double g align-apart">
          <!-- @todo Seth why doesn't strong make things bold? -->
          <p class="mg--reset@medium g__col">
            Top {{ resultCount }} results: filtered to
            <strong style="font-weight: 700;">{{ appliedFacetValues.join(" ") }}</strong> courses
          </p>
          <a class="g__col" :href="allResultsUrl">View all results</a>
        </div>
      </div>
      <search-results outer-class="g">
        <template #hits>
          <summary-hit
            v-for="hit in hits"
            :key="hit.ItemFullPath"
            :hit="hit"
            class="m-snippet g__col g__col-12 g__col-4@large"
          />
        </template>
      </search-results>
    </modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import { SET_RESULTS_SHOWN } from "@/js/search/store/mutations";

import Modal from "@/components/molecules/modal/vue-components/Modal";
import OverlayQuery from "@/js/search/components/query/OverlayQuery";
import SummaryFilters from "@/js/search/components/filters/SummaryFilters";
import SearchResults from "@/js/search/components/results/SearchResults";
import SummaryHit from "@/js/search/components/results/SummaryHit";

export default {
  components: {
    Modal,
    OverlayQuery,
    SearchResults,
    SummaryFilters,
    SummaryHit,
  },
  computed: {
    ...mapGetters(["hits", "showResults", "appliedFacets"]),
    ...mapState({
      resultCount: ({ search }) => search.perPage,
      defaultSearchUrl: ({ search }) => search.defaultSearchUrl,
      searchLinkQs: ({ search }) => search.searchLinkQs,
    }),
    allResultsUrl() {
      // @todo
      return `${this.defaultSearchUrl}?${this.searchLinkQs}`;
    },
    appliedFacetValues() {
      return this.appliedFacets.reduce((result, facet) => {
        return [...result, facet.values.map((v) => v.label.toLowerCase())];
      }, []);
    },
  },
  methods: {
    handleClose() {
      this.$store.commit(SET_RESULTS_SHOWN, false);
    },
  },
};
</script>
