<template>
  <button v-if="course" type="button" class="btn" @click="toggleSavedCourse({ CourseGuid })">
    <icon name="book" :height="20" :width="24" class="m-course-compare-btn__icon" />
    <span class="m-course-compare-btn__text"
      >{{ course.isSaved ? `Remove from` : `Add to` }} course compare</span
    >
  </button>
</template>
<script>
import Icon from "@/components/atoms/icon/Icon";

import store, { actions, queries } from "../store";

export default {
  components: {
    Icon,
  },
  props: {
    CourseGuid: {
      required: true,
      type: String,
    },
  },
  data() {
    return store;
  },
  computed: {
    course() {
      return queries.getCourseByGuid(this.CourseGuid);
    },
  },
  methods: {
    ...actions,
  },
};
</script>
