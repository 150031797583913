/* eslint-disable no-param-reassign, no-underscore-dangle */

import capitalize from "underscore.string/capitalize";

import {
  SET_BREAKPOINT,
  SET_CONFIG,
  SET_QUERY_FOCUS,
  SET_RESULTS_SHOWN,
  TOGGLE_MOBILE_FILTERS,
} from "@/js/search/store/mutations";

import BREAKPOINTS from "@/js/design/breakpoints";

function generateBreakpoints(breakpoints) {
  return Object.keys(breakpoints).reduce((acc, name) => {
    acc[name] = false;
    return acc;
  }, {});
}

function generateBreakpointGetters(breakpoints) {
  return Object.keys(breakpoints).reduce((acc, name) => {
    acc[`is${capitalize(name, true)}`] = (state) => state.breakpoints[name];

    return acc;
  }, {});
}

export default {
  state: {
    queryHasFocus: true,
    config: null,
    breakpoints: generateBreakpoints(BREAKPOINTS),
    showResults: false,
    mobileFiltersShown: false,
    storedQs: "",
  },
  mutations: {
    [SET_BREAKPOINT](state, { name, matches }) {
      state.breakpoints[name] = matches;
    },
    [SET_RESULTS_SHOWN](state, showResults) {
      state.showResults = showResults;
    },
    [SET_QUERY_FOCUS](state, hasFocus) {
      state.queryHasFocus = hasFocus;
    },
    [SET_CONFIG](state, config) {
      state.config = config;
    },
    [TOGGLE_MOBILE_FILTERS](state) {
      state.mobileFiltersShown = !state.mobileFiltersShown;
    },
  },
  getters: {
    ...generateBreakpointGetters(BREAKPOINTS),
    isMobile: (state, getters) => !getters.isTablet && !getters.isDesktop,
    isTablet: (state, getters) => getters.isMedium && !getters.isDesktop,
    isDesktop: (state, getters) => getters.isLarge,
    showMobileFilters: (state, getters) => getters.isMobile && state.mobileFiltersShown,
    showResults: (state) => state.showResults,
    controlsLayout: ({ config }) => config && config.CONTROLS_LAYOUT,
    hasSidebar: ({ config }, { showResults }) =>
      showResults ||
      (config &&
        (config.CONTROLS_LAYOUT ? config.CONTROLS_LAYOUT.toLowerCase() === "sidebar" : true)),
    showFilters: ({ config }) =>
      // @todo refactor
      // eslint-disable-next-line no-nested-ternary
      !config
        ? false
        : config.CONTROLS_LAYOUT
        ? config.CONTROLS_LAYOUT.toLowerCase() !== "none"
        : true,
  },
};
