import Vue from "vue";

import { $$, appendToBody } from "@/js/helpers/dom-helper";

import CourseCompare from "./vue-components/CourseCompare";
import CcButton from "./vue-components/CourseCompareButton";

import CourseApiService from "./services/CourseApiService";

import { actions } from "./store";

const getElementCourseGuid = (el, guidAttr) => el.getAttribute(guidAttr);

const discoverElementCourse = async (el, attrs, api) => {
  let courseData;

  try {
    courseData = JSON.parse(el.getAttribute(attrs.data));
  } catch {
    courseData = await api.getCourseByGuid(getElementCourseGuid(el, attrs.guid));
  }

  actions.addKnownCourse(courseData);
};

const replaceButton = (el, guidAttr) => {
  return new Vue({
    el,
    name: "CcButtonWrapper",
    render: (h) =>
      h(CcButton, {
        props: {
          CourseGuid: getElementCourseGuid(el, guidAttr),
        },
      }),
  });
};
const init = (config) => {
  const { compareFormConfig, apiConfig, dataAttrs, selectors } = config;

  const api = new CourseApiService({ endpoint: apiConfig.endpoint });

  const buttons = $$(selectors.trigger);

  actions.loadFromPersistence();
  actions.setCompareFormConfig(compareFormConfig);

  buttons.forEach((btn) => discoverElementCourse(btn, dataAttrs, api));

  return {
    api,
    tray: new Vue({
      el: appendToBody("div"),
      name: "CcWrapper",
      render: (h) => h(CourseCompare),
    }),
    buttons: buttons.map((btn) => replaceButton(btn, dataAttrs.guid)),
    config,
  };
};

// just init the last config if many
export default (configs) => configs.slice(-1).map((c) => init(c));
