<template>
  <div v-if="isShown" :id="id" :class="className" class="m-modal is--open">
    <div class="m-modal__inner">
      <button title="Close" class="close" :aria-controls="id" @click="hide"></button>
      <div class="m-modal__content-scroll">
        <div class="m-modal__content">
          <slot>
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="content"></div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uuid from "@/js/lib/uuid";
import modalHelper from "@/js/vue-mixins/modalHelper";

export default {
  mixins: [modalHelper],
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    content: {
      required: false,
      type: String,
      default: "",
    },
    className: {
      required: false,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: `modal-${uuid()}`,
    };
  },
  watch: {
    isShown(isShown) {
      return isShown ? this.preventBodyScroll() : this.allowBodyScroll();
    },
  },
};
</script>
