<template>
  <div class="m-self-selection">
    <question
      :question="opts.question"
      :answers="opts.answers"
      @resolve="resolve"
      @desolve="desolve"
    />
  </div>
</template>
<script>
import Question from "./Question";

export default {
  components: {
    Question,
  },
  props: {
    opts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resolved: null,
    };
  },
  methods: {
    resolve(value) {
      this.resolved = value;
      this.$emit("resolve", value);
    },
    desolve() {
      this.resolved = null;
      this.$emit("desolve");
    },
  },
};
</script>
