<template>
  <nav class="m-nav--masthead" aria-label="Primary nav">
    <ul class="m-nav--masthead__level-1">
      <li
        v-for="(primaryItem, idx) in state.navData.PrimaryItems"
        :id="id(kebab(primaryItem.Title))"
        :key="idx"
        class="g__col g__col-12 g__col-auto@large"
      >
        <a
          :ref="REFS.PRIMARY_ITEMS"
          :href="primaryItem.Url"
          @focus="(e) => bindArrowShortCuts(e, childRefName(idx))"
          @blur="(e) => unbindArrowShortCuts(e, childRefName(idx))"
          v-html="primaryItem.Title"
        />
        <ul
          v-if="primaryItem.Children"
          v-show="state.isMenuExpanded"
          class="m-nav--masthead__level-2"
        >
          <li v-for="childItem in primaryItem.Children" :key="childItem.url">
            <a
              :ref="childRefName(idx)"
              :href="childItem.Url"
              @focus="(e) => bindArrowShortCuts(e, childRefName(idx))"
              @blur="(e) => unbindArrowShortCuts(e, childRefName(idx))"
              v-html="childItem.Title"
            />
          </li>
        </ul>
      </li>
      <li
        v-if="state.navData.MorePrimaryItems"
        :id="id(kebab('more'))"
        class="g__col g__col-12 g__col-auto@large"
      >
        <button
          :id="id('button-more')"
          :ref="REFS.MORE_BUTTON"
          :aria-expanded="state.isMenuExpanded"
          class="m-nav--masthead__btn"
          type="button"
          @click="toggleMenu"
          @focus="(e) => bindArrowShortCuts(e, childRefName(REFS.MORE_ITEMS_SUFFIX))"
          @blur="(e) => unbindArrowShortCuts(e, childRefName(REFS.MORE_ITEMS_SUFFIX))"
        >
          {{ state.isMenuExpanded ? "Less" : "More" }}&nbsp;
          <icon
            name="chevron"
            :style="{
              transform: state.isMenuExpanded ? `rotate(180deg)` : ``,
            }"
          />
        </button>
        <ul v-show="state.isMenuExpanded" class="m-nav--masthead__level-2">
          <li v-for="moreItem in state.navData.MorePrimaryItems" :key="moreItem.Url">
            <a
              :ref="childRefName(REFS.MORE_ITEMS_SUFFIX)"
              :href="moreItem.Url"
              @focus="(e) => bindArrowShortCuts(e, childRefName(REFS.MORE_ITEMS_SUFFIX))"
              @blur="(e) => unbindArrowShortCuts(e, childRefName(REFS.MORE_ITEMS_SUFFIX))"
              v-html="moreItem.Title"
            />
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
import Mousetrap from "mousetrap";
import store, { actions } from "@/components/molecules/masthead/store";
import Icon from "@/components/atoms/icon/Icon";

import kebab from "lodash.kebabcase";
import getUuid from "@/js/lib/uuid";

const uuid = getUuid();
const fakeEvent = {
  preventDefault: () => {},
};

const REFS = {
  PRIMARY_ITEMS: "primaryItems",
  CHILD_ITEMS_PREFIX: "childItems",
  MORE_BUTTON: "moreButton",
  MORE_ITEMS_SUFFIX: "more",
};

export default {
  components: {
    Icon,
  },
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    REFS: () => ({ ...REFS }),
  },
  methods: {
    ...actions,
    kebab,
    id(prefix = "item") {
      return `nav-${prefix}-${uuid}`;
    },
    bindKeyboardShortcuts() {
      Mousetrap.bind("esc", this.close);
    },
    unbindKeyboardShortcuts() {
      Mousetrap.unbind("esc");
    },
    close() {
      this.toggleMenu();
      this.$refs[REFS.MORE_BUTTON].focus();
    },
    bindArrowShortCuts({ target }, ref) {
      this.$nextTick(() => {
        if (this.isRef(REFS.PRIMARY_ITEMS, target)) {
          Mousetrap.bind("left", () => this.focusPrevRefNode(REFS.PRIMARY_ITEMS, target));
          Mousetrap.bind("right", () => this.focusNextPrimaryNode(target));
          Mousetrap.bind("down", (e) => this.focusFirstChildNode(ref, target, e));
        } else if (this.isRef(ref, target)) {
          Mousetrap.bind("up", (e) => this.focusPrevChildNode(ref, target, e));
          Mousetrap.bind("down", (e) => this.focusNextRefNode(ref, target, e));

          Mousetrap.bind("left", (e) => this.focusPrevColumnChildNode(ref, target, e));
          Mousetrap.bind("right", (e) => this.focusNextColumnChildNode(ref, target, e));
        } else if (this.isRef(REFS.MORE_BUTTON, target)) {
          Mousetrap.bind("left", () => {
            this.focusRefByIndex(REFS.PRIMARY_ITEMS, this.$refs[REFS.PRIMARY_ITEMS].length - 1);
          });
          Mousetrap.bind("down", (e) => this.focusFirstChildNode(ref, target, e));
        }
      });
    },
    unbindArrowShortCuts() {
      this.$nextTick(() => {
        Mousetrap.unbind(["left", "right", "down", "up"]);
      });
    },
    isPrimaryItemNode(aDomNode) {
      return this.$refs[REFS.PRIMARY_ITEMS].includes(aDomNode);
    },
    childRefName: (idx) => `${REFS.CHILD_ITEMS_PREFIX}-${idx}`,
    isRef(refName, aDomNode) {
      let $ref = this.$refs[refName];
      let result = false;

      if ($ref) {
        if (!($ref instanceof Array)) {
          $ref = [$ref];
        }
        result = $ref.includes(aDomNode);
      }

      return result;
    },
    getRefNodeIndex(refName, theDomNode) {
      return this.$refs[refName].findIndex((aNode) => aNode === theDomNode);
    },
    getChildNavNode(refName, theDomNode) {
      return this.$refs[refName].find((aChildNode) =>
        aChildNode.closest(`#${theDomNode.parentElement.getAttribute("id")}`)
      );
    },
    getParentNavNode(aDomNode) {
      const ids = [this.$refs[REFS.MORE_BUTTON], ...this.$refs[REFS.PRIMARY_ITEMS]].map(
        (aNode) => `#${aNode.parentNode.id}`
      );

      return [...aDomNode.closest(ids.join(",")).children].find(
        (aNode) => this.isRef(REFS.PRIMARY_ITEMS, aNode) || this.isRef(REFS.MORE_BUTTON, aNode)
      );
    },
    focusRefByIndex(refName, idx) {
      let focused = false;
      if (!Number.isNaN(idx) && idx > -1 && idx < this.$refs[refName].length) {
        this.$refs[refName][idx].focus();
        focused = true;
      }
      return focused;
    },
    focusPrevRefNode(refName, aDomNode, e = fakeEvent) {
      e.preventDefault();
      return this.focusRefByIndex(refName, this.getRefNodeIndex(refName, aDomNode) - 1);
    },
    focusNextRefNode(refName, aDomNode, e = fakeEvent) {
      e.preventDefault();
      return this.focusRefByIndex(refName, this.getRefNodeIndex(refName, aDomNode) + 1);
    },
    focusNextPrimaryNode(aDomNode, e = fakeEvent) {
      e.preventDefault();
      if (!this.focusNextRefNode(REFS.PRIMARY_ITEMS, aDomNode)) {
        this.$refs[REFS.MORE_BUTTON].focus();
      }
    },
    focusFirstChildNode(refName, aDomNode, e = fakeEvent) {
      e.preventDefault();
      if (!this.state.isMenuExpanded) {
        this.toggleMenu();
      }
      this.getChildNavNode(refName, aDomNode).focus();
    },
    focusPrevChildNode(refName, aDomNode, e = fakeEvent) {
      e.preventDefault();
      if (!this.focusPrevRefNode(refName, aDomNode)) {
        this.getParentNavNode(aDomNode).focus();
      }
    },
    focusPrevColumnChildNode(refName, aDomNode, e = fakeEvent) {
      e.preventDefault();

      let result = false;
      let prevParentNodeIdx;

      const parentNode = this.getParentNavNode(aDomNode);

      if (this.$refs[REFS.MORE_BUTTON] === parentNode) {
        prevParentNodeIdx = this.$refs[REFS.PRIMARY_ITEMS].length - 1;
      } else {
        prevParentNodeIdx = this.getRefNodeIndex(REFS.PRIMARY_ITEMS, parentNode) - 1;
      }

      if (prevParentNodeIdx >= 0) {
        result = this.focusRefByIndex(
          this.childRefName(prevParentNodeIdx),
          Math.min(
            this.getRefNodeIndex(refName, aDomNode),
            this.$refs[this.childRefName(prevParentNodeIdx)].length - 1
          )
        );
      }

      return result;
    },
    focusNextColumnChildNode(refName, aDomNode, e = fakeEvent) {
      e.preventDefault();

      let result = false;

      const parentNode = this.getParentNavNode(aDomNode);
      let nextParentNodeIdx = this.getRefNodeIndex(REFS.PRIMARY_ITEMS, parentNode) + 1;

      if (nextParentNodeIdx >= this.$refs[REFS.PRIMARY_ITEMS].length) {
        nextParentNodeIdx = REFS.MORE_ITEMS_SUFFIX;
      }

      result = this.focusRefByIndex(
        this.childRefName(nextParentNodeIdx),
        Math.min(
          this.getRefNodeIndex(refName, aDomNode),
          this.$refs[this.childRefName(nextParentNodeIdx)].length - 1
        )
      );

      return result;
    },
  },
  watch: {
    "state.isMenuExpanded": function watchIsMenuExpanded(isMenuExpanded) {
      return isMenuExpanded ? this.bindKeyboardShortcuts() : this.unbindKeyboardShortcuts();
    },
  },
};
</script>
