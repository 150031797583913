import { render, staticRenderFns } from "./SearchTitle.vue?vue&type=template&id=45968e96&scoped=true&"
import script from "./SearchTitle.vue?vue&type=script&lang=js&"
export * from "./SearchTitle.vue?vue&type=script&lang=js&"
import style0 from "./SearchTitle.vue?vue&type=style&index=0&id=45968e96&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45968e96",
  null
  
)

export default component.exports