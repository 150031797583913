<template>
  <portal>
    <div class="shu-design-system-v1-0">
      <div class="m-course-compare" aria-live="polite">
        <transition-expand>
          <course-compare-tray v-if="state.isTrayExpanded" id="course-compare-tray" />
        </transition-expand>
        <course-compare-toggle
          aria-controls="course-compare-tray"
          :is-expanded="state.isTrayExpanded"
          :class="{
            'has-changed': state.hasRecentlyChanged && !state.isTrayExpanded,
            'is--shown': !!savedCourseCount,
          }"
          :tabindex="!savedCourseCount ? `-1` : ``"
          @toggle="toggleTray"
        />
      </div>
    </div>
  </portal>
</template>
<script>
import { Portal } from "@linusborg/vue-simple-portal";
import CourseCompareToggle from "./CourseCompareToggle";
import CourseCompareTray from "./CourseCompareTray";
import TransitionExpand from "./transitions/TransitionExpand";

import store, { actions } from "../store";

export default {
  components: {
    CourseCompareToggle,
    CourseCompareTray,
    Portal,
    TransitionExpand,
  },
  data() {
    return store;
  },
  methods: {
    ...actions,
  },
  watch: {
    savedCourseCount(newValue, oldValue) {
      if (newValue > oldValue && !this.state.isTrayExpanded && !this.isMobile) {
        this.toggleTray();
      }
      if (newValue === 0 && this.state.isTrayExpanded) {
        this.toggleTray();
      }
    },
  },
};
</script>
<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition-duration: 0.25s;
}
</style>
