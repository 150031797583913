<template>
  <div>
    <featured-search />
    <portal :selector="portalSelector">
      <search-overlay />
    </portal>
  </div>
</template>
<script>
import { Portal } from "@linusborg/vue-simple-portal";
import { mapState } from "vuex";

import { SET_RESULTS_SHOWN } from "@/js/search/store/mutations";

import BaseSearchWrapper from "@/js/search/components/base/BaseSearchWrapper";
import FeaturedSearch from "@/js/search/components/query/FeaturedSearch";
import SearchOverlay from "@/js/search/components/overlay/SearchOverlay";

export default {
  name: "OverlaySearch",
  components: {
    FeaturedSearch,
    Portal,
    SearchOverlay,
  },
  extends: BaseSearchWrapper,
  props: {
    portalSelector: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      query: ({ search }) => search.query,
    }),
  },
  watch: {
    query(newQuery) {
      if (newQuery) {
        this.$store.commit(SET_RESULTS_SHOWN, true);
      }
    },
  },
};
</script>
