<template>
  <div v-if="modalShown" :id="id" class="m-modal is--open">
    <div class="m-modal__inner">
      <button title="Close" class="close" :aria-controls="id" @click="hide"></button>
      <h3>{{ currentQuestion.question }}</h3>
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="currentQuestion.answer"></div>
    </div>
  </div>
</template>
<script>
import modalHelper from "@/js/vue-mixins/modalHelper";

export default {
  mixins: [modalHelper],
  props: {
    currentQuestion: {
      required: false,
      type: [Object, null],
      default: null,
    },
    breakpoints: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      id: `faq-modal-${Math.floor(Math.random() * 99999999)}`,
    };
  },
  computed: {
    modalShown() {
      return this.currentQuestion && !this.breakpoints.large;
    },
  },
  watch: {
    modalShown(isShown) {
      return isShown ? this.preventBodyScroll() : this.allowBodyScroll();
    },
  },
};
</script>
