<template>
  <button
    :aria-label="label"
    :disabled="!hasPrevPage"
    class="m-pagination__control"
    @click.prevent="pagePrev"
  >
    <span class="chevron chevron--left chevron--black"></span>
  </button>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["hasPrevPage", "pageForUi"]),
    label() {
      return this.hasPrevPage
        ? `Go to page ${this.pageForUi - 1}`
        : "Go to previous page (disabled)";
    },
  },
  methods: {
    ...mapActions(["pagePrev"]),
  },
};
</script>
