export const $ = (s) => document.querySelector(s);
export const $$ = (s) => [...document.querySelectorAll(s)];

export const appendToBody = (tag) => {
  const el = document.createElement(tag);
  document.body.appendChild(el);

  return el;
};

export default {
  $,
  $$,
  appendToBody,
};
