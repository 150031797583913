export const arrayWithout = (arr = [], value) => arr.filter((v) => v !== value);

export const arrayWith = (arr = [], value) => arrayWithout(arr, value).concat(value);

export const arrayToggle = (arr = [], value) =>
  arr.includes(value) ? arrayWithout(arr, value) : arrayWith(arr, value);

export default {
  arrayToggle,
  arrayWith,
  arrayWithout,
};
