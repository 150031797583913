import enquire from "enquire.js";

import BREAKPOINTS from "@/js/design/breakpoints";

const breakpointState = {};

export const registerBreakpoint = (name, px) => {
  const ems = px / 16;
  const breakpoint = `(min-width: ${ems}em)`;
  const { matches } = window.matchMedia(breakpoint);

  // set initial state
  breakpointState[name] = matches;

  // track changes
  enquire.register(breakpoint, {
    match() {
      breakpointState[name] = true;
    },
    unmatch() {
      breakpointState[name] = false;
    },
  });
};

export default () => {
  Object.entries(BREAKPOINTS).forEach(([name, px]) => {
    registerBreakpoint(name, px);
  });

  return breakpointState;
};
