<template>
  <form class="m-overlay-search__form">
    <label class="sr-only" for="overlay-search">Search term</label>
    <query-input
      id="overlay-search"
      class-name="m-overlay-search__input"
      name="q"
      placeholder="Search for something…"
    />
    <span class="m-overlay-search__icon">
      <span class="sr-only">Search</span>
      <icon name="search" title="Search icon" class="u-text-color-primary" />
    </span>
  </form>
</template>
<script>
import SearchBox from "@/js/search/components/query/SearchBox";
import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    Icon,
  },
  extends: SearchBox,
};
</script>
