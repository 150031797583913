<template>
  <li>
    <template v-if="hasChildren">
      <button
        :id="id('expand')"
        :title="title"
        :aria-controls="id('children')"
        :aria-expanded="isExpanded ? 'true' : 'false'"
        class="m-nav--masthead__toggle--level-2"
        type="button"
        @click="toggleExpanded"
      >
        <span v-html="item.Title" />
        <icon
          name="chevron"
          :style="{
            transform: isExpanded ? `rotate(180deg)` : ``,
          }"
        />
      </button>
      <ul :id="id('children')" :hidden="!isExpanded" class="m-nav--masthead__level-2 is--expanded">
        <li v-if="showOverview">
          <a :id="id('link')" :href="item.Url" :title="`Overview page for: ${item.Title}`"
            >Overview</a
          >
        </li>
        <li v-for="child in item.Children" :key="child.url">
          <a :id="id('child', child)" :href="child.Url" v-html="child.Title" />
        </li>
      </ul>
    </template>
    <template v-else>
      <a :href="item.Url" v-html="item.Title" />
    </template>
  </li>
</template>
<script>
import kebab from "lodash.kebabcase";
import getUuid from "@/js/lib/uuid";
import Icon from "@/components/atoms/icon/Icon";

const uuid = getUuid();

export default {
  components: {
    Icon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showOverview: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      uuid,
    };
  },
  computed: {
    hasChildren() {
      return this.item.Children && this.item.Children.length;
    },
    title() {
      return `${this.isExpanded ? `Contract` : `Expand`} section: ${this.item.Title}`;
    },
  },
  methods: {
    id(prefix = "nav", overideItem = null) {
      const item = overideItem ?? this.item;

      return `${prefix}-${kebab(item.Title)}-${uuid}`;
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
