<template>
  <div>
    <back-link :item="parentItem" />

    <div class="wrapper p-top">
      <nav class="m-nav-tier2-mobile__nav m-nav-stacked">
        <ul>
          <li v-for="navItem in contentNavItems" :key="navItem.Url" class="m-nav-stacked__item">
            <nav-item :item="navItem" />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import NavItem from "../TierNavItem";
import BackLink from "../TierNavBackLink";

export default {
  components: {
    BackLink,
    NavItem,
  },
  props: {
    contentNavItems: {
      type: Array,
      required: true,
    },
    parentItem: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },
  methods: {
    activeClass(navItem) {
      return navItem.IsCurrent ? "is--active" : "";
    },
  },
};
</script>
