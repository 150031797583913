import {
  SET_AVAILABLE_YEARS_OF_ENTRY,
  SET_CONFIG,
  SET_HITS_PER_PAGE,
  SET_QUERY,
  SET_RESULTS_SHOWN,
  SET_SOLR_CONFIG,
  SET_UI_LABELS,
  SET_USE_URL_PARAM_STATE,
  SET_DEFAULT_SEARCH_URL,
} from "@/js/search/store/mutations";

import { SOLR_DICTIONARY_DEFAULTS } from "@/js/search/constants/solrDictionaryDefaults";

import store from "./store";

export default function initStore(CONFIG) {
  store.commit(SET_CONFIG, CONFIG);
  store.commit(SET_SOLR_CONFIG, {
    baseUrl: CONFIG.API_URL,
    indexName: CONFIG.INDEX_NAME,
    dictionary: { ...SOLR_DICTIONARY_DEFAULTS, ...CONFIG.SOLR_DICTIONARY },
  });

  store.commit(SET_AVAILABLE_YEARS_OF_ENTRY, CONFIG.AVAILABLE_YEARS_OF_ENTRY);

  if (CONFIG.SHOW_RESULTS_ON_INIT) {
    store.commit(SET_RESULTS_SHOWN, CONFIG.SHOW_RESULTS_ON_INIT);
  }
  if (CONFIG.DEFAULT_SEARCH_URL) {
    store.commit(SET_DEFAULT_SEARCH_URL, CONFIG.DEFAULT_SEARCH_URL);
  }

  // set initial query state
  if (CONFIG.PRELOAD_QUERY) {
    store.dispatch("preloadQuery", CONFIG.PRELOAD_QUERY);
  }

  if (CONFIG.UI_LABELS) {
    store.commit(SET_UI_LABELS, CONFIG.UI_LABELS);
  }

  if (CONFIG.PAGE_SIZE) {
    store.commit(SET_HITS_PER_PAGE, CONFIG.PAGE_SIZE);
  }

  if (typeof CONFIG.USE_URL_PARAM_STATE !== "undefined") {
    store.commit(SET_USE_URL_PARAM_STATE, CONFIG.USE_URL_PARAM_STATE);
  }

  if (CONFIG.QUERY) {
    store.commit(SET_QUERY, CONFIG.QUERY);
  }

  return store;
}
