import Vue from "vue";

import { SET_YEAR_OF_ENTRY } from "@/js/search/store/mutations";

import { mapState } from "vuex";

const savedProperties = {
  yearOfEntry: SET_YEAR_OF_ENTRY,
};

const mapWatchers = (properties) =>
  Object.keys(properties).reduce((result, prop) => {
    // eslint-disable-next-line no-param-reassign
    result[prop] = function writeProp(newValue) {
      this.writeLocal(prop, newValue);
    };
    return result;
  }, {});

const PersistStatePlugin = Vue.extend({
  data() {
    return {
      savedProperties,
    };
  },
  computed: {
    ...mapState({
      yearOfEntry: ({ search }) => search.yearOfEntry,
      solrDictionary: ({ search }) => search.solrDictionary,
      reverseDictionary: ({ search }) => search.reverseDictionary,
    }),
  },
  watch: {
    ...mapWatchers(savedProperties),
  },
  created() {
    this.readSavedState(this.savedProperties);
  },
  methods: {
    readSavedState(props) {
      Object.entries(props).forEach(([prop, mutation]) => {
        const value = this.readLocal(prop);

        if (value) {
          this.writeStoreState(mutation, value);
        }
      });
    },
    writeStoreState(mutation, value) {
      this.$store.commit(mutation, value);
    },
    writeLocal: (name, value) => window.localStorage.setItem(name, value),
    readLocal: (name) => window.localStorage.getItem(name),
  },
});

export default function initPersistStatePlugin(store) {
  /* eslint-disable no-param-reassign */
  store.persistStatePlugin = new PersistStatePlugin({ store });
}
