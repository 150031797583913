<template>
  <div
    class="wrapper island island--white"
    :class="{
      'is--fixed shadow': isFixed,
      'is--off-canvas-top': isFixed && !isShown,
    }"
    :data-fixednav-container="isFixed"
  >
    <span v-if="title" class="meta meta--highlight">{{ title }}</span>
    <nav class="m-nav m-nav--docked">
      <button
        v-if="isExpandable"
        class="nav-item nav-item--primary nav-item--chevron nav-item--block"
        @click.prevent="toggleExpanded"
      >
        <span class="fw-500 u-text-color-black">{{
          isExpanded ? "On this page" : defaultItem.title
        }}</span>
        <span
          class="chevron"
          role="presentation"
          style="padding-left: 0;"
          :class="{ 'chevron--up': isExpanded }"
        ></span>
      </button>
      <div v-show="isExpanded" class="m-nav__inner">
        <a
          v-for="navItem in navItems"
          :key="navItem.id"
          :href="'#' + navItem.id"
          :class="{ 'nav-item--primary': !isActive(navItem) }"
          class="nav-item"
          @click.prevent="goItem(navItem)"
        >
          <span>{{ navItem.title }}</span>
        </a>
      </div>
    </nav>
  </div>
</template>
<script>
import InPageNavBase from "./InPageNavBase";

export default {
  extends: InPageNavBase,
  props: {
    title: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isExpanded: !this.isExpandable,
    };
  },
  computed: {
    defaultItem() {
      return this.currentItem || this.navItems[0];
    },
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
    goItem(navItem) {
      if (this.isExpandable) {
        this.isExpanded = false;
      }
      this.scrollToSection(navItem);
    },
    // override scrollHelper setScrolledHeight method for mobile nav
    setScrolledHeight() {
      this.scrolledHeight = this.$el.querySelector(".nav-item").offsetHeight;
    },
  },
};
</script>
