<template>
  <fieldset class="fieldset">
    <legend class="u-screen-reader-only">Refine results</legend>
    <div class="align-v-center mg-top">
      <span role="presentation" class="fw-700 mg-right">Refine results</span>
      <form-option-group
        v-for="facet in filtersForUi.main"
        :key="facet.name"
        :options="facet.values"
        :control-wrapper-class="['mg-right--half control-inline micro']"
        tag="span"
        type="checkbox"
        @change="onChange"
      />
    </div>
  </fieldset>
</template>
<script>
import { TOGGLE_SEARCH_FACET_VALUE } from "@/js/search/store/mutations";
import FiltersBase from "@/js/search/components/filters/FiltersBase";
import FormOptionGroup from "@/js/search/components/form-controls/FormOptionGroup";

export default {
  components: {
    FormOptionGroup,
  },
  extends: FiltersBase,
  methods: {
    onChange(facet) {
      this.$store.commit(TOGGLE_SEARCH_FACET_VALUE, facet);
    },
  },
};
</script>
