import axios from "axios";
import CourseApiError from "../errors/CourseApiError";

export default class {
  constructor({ endpoint }) {
    this.endpoint = endpoint;
  }

  async getCourseByGuid(CourseGuid) {
    const request = await axios({
      method: "get",
      url: this.endpoint,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
      params: {
        CourseGuid,
      },
    });

    const { data } = request;

    if (!request.status === 200) {
      throw new CourseApiError("Error connecting to Courses API", request);
    }

    return data.Course;
  }
}
