<template>
  <button :disabled="!hasRefinements" :class="[activeClass]" @click.prevent="resetAll">
    {{ title }}
  </button>
</template>

<script>
import { mapGetters } from "vuex";

import { CLEAR_SEARCH } from "@/js/search/store/mutations";

export default {
  props: {
    activeClassName: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Reset filters",
    },
  },
  computed: {
    ...mapGetters(["hasRefinements"]),
    activeClass() {
      return this.hasRefinements ? this.activeClassName : "";
    },
  },
  methods: {
    resetAll() {
      this.$store.commit(CLEAR_SEARCH);
    },
  },
};
</script>
