<template>
  <div class="wrapper">
    <p>
      <strong>{{ message }}</strong>
    </p>
    <form action="/search-results" method="get">
      <div class="island island--grey island--bordered-full p--double p-bottom--half">
        <div class="g">
          <div class="g__col g__col-12 g__col-9@medium g__col-10@large">
            <div class="control">
              <input id="q" type="text" name="q" class="input" placeholder="Start searching..." />
              <input type="hidden" name="contenttype" value="56c77fe4dfaa4587832abfa1a09b52eb" />
            </div>
          </div>
          <div class="g__col g__col-auto@medium align-right p-bottom">
            <button type="submit" class="btn btn--primary btn--block">Search</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
