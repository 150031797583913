<template>
  <div class="p-top--double p-bottom--double" :class="wrapperClass">
    <div class="wrapper">
      <div class="g align-center">
        <div class="g__col g__col-10">
          <h2 v-if="heading" class="h4" v-html="heading" />
          <form
            class="m-site-search"
            v-bind="formAttrs"
            novalidate="novalidate"
            autocomplete="off"
            autocorrect="on"
          >
            <div class="m-site-search__wrapper bg-white">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <label v-if="label" class="sr-only" :for="inputId" v-html="label" />
              <slot />
            </div>
            <slot name="after" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    inputId: {
      type: String,
      required: false,
      default: ``,
    },
    label: {
      type: String,
      required: false,
      default: ``,
    },
    heading: {
      type: String,
      required: false,
      default: ``,
    },
    formAttrs: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    wrapperClass: {
      type: [String, Array],
      default: () => ["bg-mercury"],
    },
  },
};
</script>
