<template>
  <div
    class="island island--unhidden island--secondary p-top p-bottom is--hidden is--visible@medium"
  >
    <div class="wrapper">
      <div class="m-nav m-nav--tier2">
        <span v-if="isTopLevel" class="nav-item nav-parent nav-item--white">
          {{ topLevelTitle }}
          <span class="chevron chevron--right chevron--inline"></span>
        </span>
        <a
          v-else-if="parentItem"
          :href="parentItem.Url"
          class="nav-item nav-parent nav-item--white"
        >
          {{ parentItem.Title }}
          <span class="chevron chevron--right chevron--inline"></span>
        </a>
        <nav ref="container" v-click-outside="clickOutsideHandler">
          <a
            v-for="(navItem, index) in navItems"
            ref="items"
            :key="navItem.Url"
            :href="navItem.Url"
            :class="[activeClass(navItem)]"
            :style="hiddenItemStyle(index)"
            class="nav-item nav-item--white"
            >{{ navItem.Title }}
          </a>
          <div v-if="hiddenNavItems.length" class="m-nav--tier2__trigger-wrap">
            <button
              :aria-controls="expandableId"
              :title="btnTitle"
              class="nav-item nav-item--white m-nav--tier2__trigger"
              @click.prevent="toggleExpanded"
            >
              {{ hiddenNavItems.length }} More...
            </button>
            <div
              v-show="isExpanded"
              :id="expandableId"
              class="m-nav--tier2__sub"
              :aria-hidden="!isExpanded"
            >
              <a
                v-for="navItem in hiddenNavItems"
                :key="navItem.Url"
                :href="navItem.Url"
                :class="[activeClass(navItem)]"
                class="link link--fancy link--plain micro"
              >
                <span
                  v-if="navItem.IsCurrent"
                  class="chevron chevron--primary chevron--right chevron--inline p-right--half"
                >
                </span>
                {{ navItem.Title }}
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import throttle from "lodash.throttle";
import vClickOutside from "v-click-outside";

import TierNavBase from "../TierNavBase";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  extends: TierNavBase,
  data() {
    return {
      renderedWidth: 0,
      renderedItems: [],
      padAmount: 80,
      throttledResizeHandler: () => {},
    };
  },
  computed: {
    hiddenNavItems() {
      if (this.lastVisibleItemIndex > -1) {
        return this.navItems.slice(this.lastVisibleItemIndex + 1, this.navItems.length);
      }
      return [];
    },
    btnTitle() {
      return this.isExpanded ? "Hide additional items" : "Show more items";
    },
    availableWidth() {
      return this.renderedWidth - this.padAmount;
    },
    totalItemWidths() {
      return this.renderedItems.reduce((t, i) => t + i.width, 0);
    },
    lastVisibleItemIndex() {
      const lastItem = [...this.renderedItems].reverse().find((item) => {
        return item.runningTotalWidth < this.availableWidth;
      });

      if (typeof lastItem !== "undefined") {
        return this.renderedItems.findIndex((i) => i === lastItem);
      }
      return -1;
    },
  },
  mounted() {
    this.throttledResizeHandler = throttle(this.resizeHandler, 300);

    this.$nextTick(() => {
      this.bindResize();
    });
    this.resizeHandler();
  },
  beforeDeactivate() {
    this.unbindResize();
  },
  beforeDestroy() {
    this.unbindResize();
  },
  methods: {
    clickOutsideHandler() {
      if (this.isExpanded) {
        this.toggleExpanded();
      }
    },
    hiddenItemStyle(itemIndex) {
      return {
        visibility: itemIndex > this.lastVisibleItemIndex ? "hidden" : "visible",
      };
    },
    resizeHandler() {
      if (this.$refs.container) {
        this.renderedWidth = this.$refs.container.offsetWidth;
        this.renderedItems = this.updateRenderedItemsWithWidths();
      }
    },
    bindResize() {
      window.addEventListener("resize", this.throttledResizeHandler);
    },
    unbindResize() {
      window.removeEventListener("resize", this.throttledResizeHandler);
    },
    updateRenderedItemsWithWidths() {
      return this.$refs.items.reduce((acc, item, idx) => {
        const style = window.getComputedStyle(item);
        const width =
          item.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        const runningTotalWidth = idx > 0 ? acc[idx - 1].runningTotalWidth + width : width;

        return acc.concat([
          {
            item,
            width,
            runningTotalWidth,
          },
        ]);
      }, []);
    },
  },
};
</script>
