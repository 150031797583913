<template>
  <modal :is-shown="true" class-name="m-modal--filters" @hide="hide">
    <h3>Refine results</h3>
    <div class="p-bottom--oct">
      <filters-mobile />
    </div>
    <div class="island island--grey p--double p-bottom is--fixed-bottom hr--dark">
      <div class="g">
        <div class="g__col g__col-auto mg-bottom">
          <filter-button-mobile class="btn--block" />
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { TOGGLE_MOBILE_FILTERS } from "@/js/search/store/mutations";

import modalHelper from "@/js/vue-mixins/modalHelper";

import Modal from "@/components/molecules/modal/vue-components/Modal";
import FilterButtonMobile from "@/js/search/components/filters/FilterButtonMobile";
import FiltersMobile from "@/js/search/components/filters/FiltersMobile";

export default {
  components: {
    FilterButtonMobile,
    FiltersMobile,
    Modal,
  },
  mixins: [modalHelper],
  mounted() {
    this.preventBodyScroll();
  },
  beforeDestroy() {
    this.allowBodyScroll();
  },
  methods: {
    hide() {
      this.allowBodyScroll();
      this.$store.commit(TOGGLE_MOBILE_FILTERS);
    },
  },
};
</script>
